import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ViewportProvider } from './hooks/ViewPortContext';

import { LandingPage, ValidateSyllabusPage, SyllabusPageRedirect, GuidelinesPage, TnCPage, MobileClassListPage } from './pages';
import InstructionsPage from './pages/instructionspage/InstructionsPage';

const App = () => {
    // document.addEventListener('contextmenu', (e) => {
    //     e.preventDefault();
    //   });
    return (
        <ViewportProvider>
            <BrowserRouter>

                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/:competitionName" element={<SyllabusPageRedirect />} />
                    <Route path="/:competitionName/syllabus" element={<SyllabusPageRedirect />} />
                    <Route path="/:competitionName/syllabus/:className" element={<ValidateSyllabusPage />} />
                    <Route path="/:competitionName/classes" element={<MobileClassListPage />} />
                    <Route path="/:competitionName/instructions" element={<InstructionsPage />} />
                    <Route path="/guidelines" element={<GuidelinesPage />} />
                    <Route path="/tnc" element={<TnCPage />} />
                </Routes>

            </BrowserRouter>
        </ViewportProvider>
    )
}

export default App