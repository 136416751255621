import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import './home.css'
import { backgroundImage_png, backgroundImage_low_res_png, backgroundImage_very_low_res_png } from '../../../../assets';
import { drawing_img, colouring_img, handwriting_img, board, arrow_forward_png } from '../../../../assets';
import ImageLoader from '../../../../utils/ImageLoader';

const Home = () => {
    const isMobile = useViewPortMobileCheck();

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const scrollToSection = (sectionId) => {
        const container = document.getElementById('LandingContents');
        const element = document.getElementById(sectionId);
        const offset = 10; // Set the desired offset value

        if (element) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const scrollOffset = (elementRect.top - containerRect.top) - (offset * window.innerHeight / 100);

            container.scrollTop += scrollOffset;
        }
    };

    const handleClassClick = (comp, button) => {
        sessionStorage.setItem('currentCompetition', comp);
        sessionStorage.setItem('currentClassID', 0);
        sessionStorage.setItem('currentBtn', button);
    }

    return isMobile
        ? (
            <div className='home' id='HomeMobile'>
                <div className='home-contents home-contents-mobile'>
                    <div className='mobile-home-top' >
                        <div className={`background-mobile ${isLoading ? 'loading' : ''}`}>
                            <ImageLoader
                                className={'background-mobile'}
                                baseID={'MobileHomePageBackgroundImage'}
                                allRes_png={[backgroundImage_very_low_res_png, backgroundImage_low_res_png, backgroundImage_png]}
                                allRes_webp={[backgroundImage_very_low_res_png, backgroundImage_low_res_png, backgroundImage_png]}
                                onImageLoad={handleImageLoad}
                                fetchPriority={'high'}
                            />
                            <div className='overlay-content'>
                                <h1 className='mobile-home-title-text tinos-font'>
                                    Amazing<br />Brushes
                                </h1>
                                <div className='mobile-home-description-top'>
                                    <h3 style={{ fontSize: '4vw', fontWeight: '600', margin: 0, padding: 0, pointerEvents: 'none', color: 'black' }}><i>The Multi-School Art Competition.</i></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-home-competitions-list' id='CompetitionsMobile'>
                        <div className='mobile-home-competitions-container home-drawing-competition-container'>
                            <div className='mobile-home-competitions-container-title home-drawing-competition-container-title'>
                                DRAWING & COLOURING<br></br>COMPETITION - 2024
                            </div>

                            <div className='mobile-home-competitions-container-image'>
                                <img src={drawing_img} className='mobile-home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='mobile-home-competitions-container-text'>
                                LKG to Class 12
                            </div>
                            <div className='mobile-home-competitions-container-links'>
                                <Link
                                    key={"DrawingSyllabus"}
                                    id={`DrawingSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Drawing", "Syllabus")}
                                    to={`/drawing/classes`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Syllabus</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"DrawingInstructions"}
                                    id={`DrawingInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Drawing", "Instructions")}
                                    to={`/drawing/instructions`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Instructions</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='mobile-home-competitions-container home-colouring-competition-container'>
                            <div className='mobile-home-competitions-container-title home-colouring-competition-container-title'>
                                COLOURING<br></br>COMPETITION - 2024
                            </div>
                            <div className='mobile-home-competitions-container-image'>
                                <img src={colouring_img} className='mobile-home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='mobile-home-competitions-container-text'>
                                LKG to Class 12
                            </div>
                            <div className='mobile-home-competitions-container-links'>
                                <Link
                                    key={"ColouringSyllabus"}
                                    id={`ColouringSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Colouring", "Syllabus")}
                                    to={`/colouring/classes`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Syllabus</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"ColouringInstructions"}
                                    id={`ColouringInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Colouring", "Instructions")}
                                    to={`/colouring/instructions`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Instructions</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='mobile-home-competitions-container home-handwriting-competition-container'>
                            <div className='mobile-home-competitions-container-title home-handwriting-competition-container-title'>
                                HANDWRITING<br></br>COMPETITION - 2024
                            </div>
                            <div className='mobile-home-competitions-container-image'>
                                <img src={handwriting_img} className='mobile-home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='mobile-home-competitions-container-text'>
                                Class 1 to Class 12
                            </div>
                            <div className='mobile-home-competitions-container-links'>
                                <Link
                                    key={"HandwritingSyllabus"}
                                    id={`HandwritingSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Handwriting", "Syllabus")}
                                    to={`/handwriting/classes`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Syllabus</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"HandwritingInstructions"}
                                    id={`HandwritingInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Handwriting", "Instructions")}
                                    to={`/handwriting/instructions`}
                                    className='mobile-home-competitions-container-link'>
                                    <div className='mobile-home-competitions-container-link-text'>Instructions</div>
                                    <div className='mobile-home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='mobile-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        : (
            <div className='home' id='HomeDesktop'>
                <div className='home-contents home-contents-desktop'>
                    <div className='home-top'>
                        <div className={`background-desktop ${isLoading ? 'loading' : ''}`}>
                            <ImageLoader
                                className={'background-desktop'}
                                baseID={'HomePageBackgroundImage'}
                                allRes_png={[backgroundImage_very_low_res_png, backgroundImage_low_res_png, backgroundImage_png]}
                                allRes_webp={[backgroundImage_very_low_res_png, backgroundImage_low_res_png, backgroundImage_png]}
                                onImageLoad={handleImageLoad}
                                fetchPriority={'high'}
                            />
                            <div className='overlay-content'>
                                <h1 className='home-title-text tinos-font'>
                                    Amazing<br />Brushes
                                </h1>
                                <div className='home-title-subtext'>
                                    <h3 style={{ fontSize: '1.5vw', fontWeight: '800', margin: 0, padding: 0, backgroundColor: 'whitesmoke' }}><i>The Multi-School Art Competition.</i></h3>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='home-competitions-list' id='CompetitionsDesktop'>
                        <div className='home-competitions-container home-drawing-competition-container '>
                            <div className='home-competitions-container-title home-drawing-competition-container-title'>
                                DRAWING & COLOURING<br></br>COMPETITION - 2024
                            </div>

                            <div className='home-competitions-container-image'>
                                <img src={drawing_img} className='home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='home-competitions-container-text'>
                                LKG to Class 12
                            </div>
                            <div className='home-competitions-container-links'>
                                <Link
                                    key={"DrawingSyllabus"}
                                    id={`DrawingSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Drawing", "Syllabus")}
                                    to={`/drawing/syllabus/LKG`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Syllabus</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"DrawingInstructions"}
                                    id={`DrawingInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Drawing", "Instructions")}
                                    to={`/drawing/instructions`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Instructions</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='home-competitions-container home-colouring-competition-container'>
                            <div className='home-competitions-container-title home-colouring-competition-container-title'>
                                COLOURING<br></br>COMPETITION - 2024
                            </div>
                            <div className='home-competitions-container-image'>
                                <img src={colouring_img} className='home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='home-competitions-container-text'>
                                LKG to Class 12
                            </div>
                            <div className='home-competitions-container-links'>
                                <Link
                                    key={"ColouringSyllabus"}
                                    id={`ColouringSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Colouring", "Syllabus")}
                                    to={`/colouring/syllabus/LKG`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Syllabus</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"ColouringInstructions"}
                                    id={`ColouringInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Colouring", "Instructions")}
                                    to={`/colouring/instructions`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Instructions</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='home-competitions-container home-handwriting-competition-container'>
                            <div className='home-competitions-container-title home-handwriting-competition-container-title'>
                                HANDWRITING<br></br>COMPETITION - 2024
                            </div>
                            <div className='home-competitions-container-image'>
                                <img src={handwriting_img} className='home-competition-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                            <div className='home-competitions-container-text'>
                                Class 1 to Class 12
                            </div>
                            <div className='home-competitions-container-links'>
                                <Link
                                    key={"HandwritingSyllabus"}
                                    id={`HandwritingSyllabusButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Handwriting", "Syllabus")}
                                    to={`/handwriting/syllabus/class-1`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Syllabus</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                                <Link
                                    key={"HandwritingInstructions"}
                                    id={`HandwritingInstructionsButton`}
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => handleClassClick("Handwriting", "Instructions")}
                                    to={`/handwriting/instructions`}
                                    className='home-competitions-container-link'>
                                    <div className='home-competitions-container-link-text'>Instructions</div>
                                    <div className='home-competitions-container-link-arrow'>
                                        <img src={arrow_forward_png} className='link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
}

export default Home