import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DelayedLink } from '../../../../hooks/DelayedLink';
import { instructions } from '../../../../models/themes';
import { name_text_white_png, deco_1, board, drawing_img, colouring_img, handwriting_img } from '../../../../assets';
import './mobileInstructions.css'
import ImageLoader from '../../../../utils/ImageLoader';

const MobileInstructions = () => {

    const { competitionName, className } = useParams();
    const competitionId = competitionName === "drawing" ? 0 : competitionName === "colouring" ? 1 : 2;

    function getCompetitionName(competitionId, isFirstCaps, isFullCaps) {
        switch (competitionId) {
            case 0:
                return (isFirstCaps ? "Drawing & Colouring" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
            case 1:
                return (isFirstCaps ? "Colouring" : isFullCaps ? "COLOURING" : "colouring");
            case 2:
                return (isFirstCaps ? "Handwriting" : isFullCaps ? "HANDWRITING" : "handwriting");
            default:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
        }
    }

    function getPageColorCode(competitionId, top, bottom) {
        switch (competitionId) {
            case 0:
                return (top ? "#6899dd" : "#c7ddfc");
            case 1:
                return (top ? "#a8ee8c" : "#daffcb");
            case 2:
                return (top ? "#e8d470" : " #faf0c0");
            default:
                return (top ? "#6899dd" : "#c7ddfc");
        }
    }

    function getImageSrc(competitionId) {
        switch (competitionId) {
            case 0:
                return [drawing_img, drawing_img, drawing_img];
            case 1:
                return [colouring_img, colouring_img, colouring_img];
            case 2:
                return [handwriting_img, handwriting_img, handwriting_img];
        }
    }

    useEffect(() => {
        document.title = `Amazing Brushes - ${getCompetitionName(competitionId, true, false)} - Instructions`
    }, []);


    return (
        <div className="mobile-instructions">
            <div className="mobile-instructions-content">

                    <div className='mobile-instructions-top-container'>
                        <div className='mobile-instructions-top-left-container'>
                            <div className='mobile-instructions-top-row' style={{ '--competition-top-colour': getPageColorCode(competitionId, false, true) }}>
                                <div className='mobile-instructions-text-logo'>
                                    <img src={name_text_white_png} className='mobile-instructions-text-logo-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                </div>
                            </div>
                            <div className='mobile-instructions-title-container'>

                                <div className='mobile-instructions-competition-title-section'>
                                    <div className='mobile-instructions-competition-title'>
                                        <div className='mobile-instructions-competition-title-top'>
                                            {getCompetitionName(competitionId, true, false)}
                                        </div>
                                        <div className='mobile-instructions-competition-title-bottom'>
                                            Competition <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-instructions-top-right-container'>
                            <div className='mobile-instructions-page-class-image-container'>
                                <ImageLoader
                                    baseID={`MobileClassListCompetitionImage${competitionId}`}
                                    className={'mobile-instructions-list-competiiton-image'}
                                    allRes_png={getImageSrc(competitionId)}
                                    allRes_webp={getImageSrc(competitionId)}
                                    fetchPriority={'high'}
                                />
                            </div>
                        </div>
                    </div>
                <div className='mobile-instructions-bottom-container' id='ThemeDetailsForClass'>
                <div className='mobile-instructions-title-text' style={{ '--background-colour': getPageColorCode(competitionId, false, true) }}>Instructions</div>
                    <div className='mobile-instructions-item'>
                        <div className='mobile-competition-syllabus-number-circle mobile-competition-syllabus-number-circle-instructions'>1</div>
                        <div className='mobile-instruction-text'><span style={{ textDecoration: 'underline', fontWeight: 700, paddingTop: '1vh' }}>Competition Eligibility and Venue:</span><br /><br /> {instructions[competitionName][0].eligibilityvenue} </div>
                    </div>
                    <div className='mobile-instructions-item'>
                        <div className='mobile-competition-syllabus-number-circle mobile-competition-syllabus-number-circle-instructions'>2</div>
                        <div className='mobile-instruction-text'><span style={{ textDecoration: 'underline', fontWeight: 700, paddingTop: '2vh' }}>Task Description:</span><br /><br /> {instructions[competitionName][0].task}</div>
                    </div>
                    <div className='mobile-instructions-item'>
                        <div className='mobile-competition-syllabus-number-circle mobile-competition-syllabus-number-circle-instructions'>3</div>
                        <div className='mobile-instruction-text'><span style={{ textDecoration: 'underline', fontWeight: 700 }}>Materials to be Used:</span><br /><br /> {instructions[competitionName][0].materials}</div>
                    </div>
                    <div className='mobile-instructions-item'>
                        <div className='mobile-competition-syllabus-number-circle mobile-competition-syllabus-number-circle-instructions'>4</div>
                        <div className='mobile-instruction-text'><span style={{ textDecoration: 'underline', fontWeight: 700 }}>{competitionId === 2 ? 'Preparation Material:' : 'Theme Selection:'}</span><br /><br /> {instructions[competitionName][0].themeselection}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileInstructions