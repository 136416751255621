import React from 'react';
import './instructionsPage.css'
import { Instructions } from './containers';

const InstructionsPage = () => {
    return (
        <div className='InstructionsPage'>
            <Instructions/>
        </div>
    )
}

export default InstructionsPage