import React from 'react';
import './footer.css'
import { useViewPortMobileCheck } from "../../../../hooks/ViewPortContext";
import { name_text_white_png } from '../../../../assets';
import { DelayedLink } from "../../../../hooks/DelayedLink";

const Footer = () => {
    const isMobile = useViewPortMobileCheck()
    return isMobile
        ? (
            <div className="footer-mobile" id='FooterMobile'>
                <div className="footer-top mobile-footer-top">
                    <div className="footer-top-left mobile-footer-top-left">
                        <div className="mobile-footer-logo">
                            <img src={name_text_white_png} alt="Amazing Brushes Logo" className='mobile-footer-logo-img'></img>
                        </div>
                    </div>
                    <div className="mobile-footer-middle">
                        <div className="mobile-footer-logo-text">
                            <div className="mobile-footer-logo-text-top">
                                New Schools,<br />Please get in touch at
                            </div>
                            <div className="mobile-footer-logo-text-email">
                                join@amazingbrushes.com
                            </div>
                        </div>
                        <div className="mobile-footer-top-right-button">
                            <DelayedLink delay="200" to="/tnc" isBounce={true} divId="Mobile-TnC" className="mobile-footer-terms-button">
                                <div className="mobile-footer-terms-button" id="Mobile-TnC">
                                    <div className='mobile-tnc-container-box'>
                                        Terms & Conditions
                                    </div>
                                </div>
                            </DelayedLink>
                        </div>
                    </div>
                </div>
                <div className="mobile-footer-bottom">
                    <div className="mobile-footer-bottom-line">
                        {'\u00a9'} 2024 Amazing Brushes.
                    </div>
                    <div className="mobile-footer-bottom-line">
                        All Rights Reserved.
                    </div>

                </div>
            </div>
        )
        : (
            <div className="footer" id='FooterDesktop'>
                <div className="footer-bottom">
                    <div className="footer-bottom-left">
                        <div className="footer-logo">
                            <img src={name_text_white_png} alt="Amazing Brushes Logo" className='footer-logo-img'></img>
                        </div>
                        <div className="footer-logo-text">
                            <div className="footer-logo-text-top">
                                New Schools,<br />Please get in touch at
                            </div>
                            <div className="footer-logo-text-email">
                                join@amazingbrushes.com
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-right">
                        <div className="footer-bottom-right-button">
                            <DelayedLink delay="200" to="/tnc" isBounce={true} divId="Desktop-TnC" className="footer-terms-button">
                                <div className="footer-terms-button" id="Desktop-TnC">
                                    <div className='tnc-container-box'>
                                        Terms & Conditions
                                    </div>
                                </div>
                            </DelayedLink>
                        </div>
                    </div>
                    <div className="footer-rights">
                        {'\u00a9'} 2024 Amazing Brushes. All Rights Reserved.
                    </div>
                </div>

            </div>
        )
}

export default Footer