import { gallery_img_01, gallery_img_02, gallery_img_03, gallery_img_04, gallery_img_05, gallery_img_06, gallery_img_07, gallery_img_08, gallery_img_09, gallery_img_10 } from '../assets';

export const gallery = [
    {
        id: 0,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#198877', '#046758', '#198877'],
        bottomColors: ['#046758', '#023d34', '#198877'],
        imageSrc: gallery_img_01,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'M. Mohamed Aziz',
        class: 'LKG',
        school: 'CSI Bain Mat. School, Chennai'
    },
    {
        id: 1,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#883fae', '#602c7b', '#883fae'],
        bottomColors: ['#602c7b', '#381948', '#883fae'],
        imageSrc: gallery_img_02,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'Evan Michael',
        class: 'UKG',
        school: 'Peniel Mat. Hr. Sec. School, Chennai'
    },
    {
        id: 2,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#e5487d', '#b23761', '#e5487d'],
        bottomColors: ['#b23761', '#7e2745', '#e5487d'],
        imageSrc: gallery_img_03,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'Tharshikha Sri',
        class: 'Class - 1',
        school: 'Sri Vinayaga Vidyalaya Sr. Sec. School, Coimbatore'
    },
    {
        id: 3,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#4b9656', '#316338', '#4b9656'],
        bottomColors: ['#316338', '#17301b', '#4b9656'],
        imageSrc: gallery_img_04,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'A. Angelina',
        class: 'Class - 2',
        school: 'Monfort Centenary Academy Mat. Hr. Sec. School, Kancheepuram'
    },
    {
        id: 4,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#eb9932', '#df861b', '#eb9932'],
        bottomColors: ['#df861b', '#83551c', '#eb9932'],
        imageSrc: gallery_img_05,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'A. Aathifa Maryam',
        class: 'Class - 3',
        school: 'Sri Ramakrishna Mat. Hr. Sec. School, Coimbatore'
    },
    {
        id: 5,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#198877', '#046758', '#198877'],
        bottomColors: ['#046758', '#023d34', '#198877'],
        imageSrc: gallery_img_06,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'S.A. Albin Saniv',
        class: 'Class - 4',
        school: 'Carmel Mat. Hr. Sec. School, Erode'
    },
    {
        id: 6,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#883fae', '#602c7b', '#883fae'],
        bottomColors: ['#602c7b', '#381948', '#883fae'],
        imageSrc: gallery_img_07,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'B. Harshitha',
        class: 'Class - 5',
        school: 'Cluny Mat. Hr. Sec. School, Salem'
    },
    {
        id: 7,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#e5487d', '#b23761', '#e5487d'],
        bottomColors: ['#b23761', '#7e2745', '#e5487d'],
        imageSrc: gallery_img_08,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'B.K. Arvind',
        class: 'Class - 6',
        school: 'Pon Vidyashram, Valasaravakkam, Chennai'
    },
    {
        id: 8,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#4b9656', '#316338', '#4b9656'],
        bottomColors: ['#316338', '#17301b', '#4b9656'],
        imageSrc: gallery_img_09,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'A.J. Anshia',
        class: 'Class - 7',
        school: 'Peniel Mat. Hr. Sec. School, Chennai'
    },
    {
        id: 9,
        topColors: ['#fff535', '#d1c700', '#fff535'],
        middleColors: ['#eb9932', '#df861b', '#eb9932'],
        bottomColors: ['#df861b', '#83551c', '#eb9932'],
        imageSrc: gallery_img_10,
        // imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        event: 'Independence Day Celebration, 2023',
        name: 'S. Tholkappian',
        class: 'Class - 8',
        school: 'Monfort Centenary Academy Mat. Hr. Sec. School, Kancheepuram'
    },
]