import React from 'react';
import { useEffect, useState } from 'react';
import './navbar.css'
import { amazing_brushes_logo, name_text_black_png, name_text_white_png } from '../../../../assets';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';

const DesktopNavBar = () => {

    const isMobile = useViewPortMobileCheck()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [navbarScale, setNavbarScale] = useState(0.9);
    const [showNewLink, setShowNewLink] = useState(false);

    const scrollToSection = (sectionId) => {
        const container = document.getElementById('LandingContents');
        const element = document.getElementById(sectionId);
        const offset = sectionId === "GalleryDesktop" ? 4 : sectionId === "CompetitionsDesktop" ? 4 : 6; // Set the desired offset value

        if (element) {
            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            const scrollOffset = (elementRect.top - containerRect.top) - (offset * window.innerWidth / 100);

            container.scrollTop += scrollOffset;
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(document.getElementById("LandingContents").scrollTop);

            // 2. Update the handleScroll function
            if (document.getElementById("LandingContents").scrollTop > 5) {
                setNavbarScale(1); // Adjust this factor to achieve desired scaling
                setShowNewLink(true);
            } else {
                setNavbarScale(0.9);
                setShowNewLink(false);
            }
        };

        function selectElementById(className) {
            return document.getElementById(className);
        }
        const sections = [
            selectElementById('CompetitionsDesktop'),
            selectElementById('GalleryDesktop'),
            selectElementById('AwardsDesktop'),
        ];
        const navItems = {
            HomeDesktop: null,
            ThemesDesktop: selectElementById('themesNavItem-desktop'),
            GalleryDesktop: selectElementById('galleryNavItem-desktop'),
            AwardsDesktop: selectElementById('awardsNavItem-desktop'),
        }
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        };

        const visibilityPercentages = {};

        function findMaxIndex(arr) {
            var max = Math.max(...arr); // Find the maximum value in the array
            var index = arr.indexOf(max); // Find the index of the maximum value
            return index;
        }

        function updateVisibilityPercentages() {
            const updatedVisibilityPercentages = {};

            sections.forEach((element) => {
                const visibilityPercentage = visibilityPercentages[element.id] || 0;
                updatedVisibilityPercentages[element.id] = visibilityPercentage;
            });

            Object.assign(visibilityPercentages, updatedVisibilityPercentages);
        }

        const initialEntries = {};

        function removeAllActive() {
            Object.values(navItems).forEach((item) => {
                if (item)
                    item.classList.remove("section-active");
            });
        }

        function observerCallback(entries, observer) {
            let maxVisibilityPercentage = -Infinity;
            let dominantEntry = null;
            let isIntersectingNew = false;

            entries.forEach((entry) => {
                initialEntries[entry.target.id] = entry;
            });

            const initialEntriesArray = Object.values(initialEntries); // Convert initialEntries into an array

            initialEntriesArray.forEach((entry) => {
                const visibilityPercentage = entry.intersectionRatio;
                visibilityPercentages[entry.target.id] = visibilityPercentage;

                if (entry.isIntersecting) {
                    isIntersectingNew = true;
                }
            });

            // Find the entry with the highest visibility percentage
            for (const entry of initialEntriesArray) {
                const visibilityPercentage = visibilityPercentages[entry.target.id];
                if (visibilityPercentage > maxVisibilityPercentage) {
                    maxVisibilityPercentage = visibilityPercentage;
                    dominantEntry = entry;
                }
            }

            if (dominantEntry) {
                const dominantNavItem = navItems[dominantEntry.target.id];
                if (!dominantNavItem) {
                    removeAllActive();
                    return;
                }

                const isActive = dominantNavItem.classList.contains("section-active");

                if (!isActive) {
                    // Remove "section-active" class from all navItems
                    removeAllActive();

                    // Add "section-active" class to the dominantNavItem
                    dominantNavItem.classList.add("section-active");
                }
            }

            if (isIntersectingNew) {
                // Recalculate visibility percentages for all elements
                updateVisibilityPercentages();
            }
        }


        const observer = new IntersectionObserver(observerCallback, observerOptions);
        const onPageLoad = () => {
            sections.forEach((sec) => {
                observer.observe(sec);
                visibilityPercentages[sec.id] = 0;
            });
            document.getElementById("LandingContents").addEventListener('scroll', handleScroll);
        };

        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            return () => {
                if (document.getElementById("LandingContents"))
                    document.getElementById("LandingContents").removeEventListener('scroll', handleScroll);
                window.removeEventListener("load", onPageLoad);
            }
        }
        return () => observer.disconnect();
    }, [isMobile, scrollPosition]);

    const navbarStyle = {
        paddingTop: `0vw`,
    };

    const navbarLinksStyle = {
        gap: '3vw', // adjust these values as necessary
        paddingRight: '3vw'
    }

    return (
        <>

            <div className='desktop-navbar' style={navbarStyle}>
                <div className='desktop-landingPage-navbar-logo'>
                    <img src={name_text_white_png} className='desktop-landingPage-navbar-name-text-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                </div>
                <div className='landingPage-navbar-links' style={navbarLinksStyle}>
                    <div className='desktop-landingPage-navbar-links-container-box black-box' onClick={() => { scrollToSection("CompetitionsDesktop"); }} id='themesNavItem-desktop'>
                        Competitions
                    </div>
                    <div className='desktop-landingPage-navbar-links-container-box gold-box' onClick={() => { scrollToSection("GalleryDesktop"); }} id='galleryNavItem-desktop'>
                        Gallery
                    </div>
                    <div className='desktop-landingPage-navbar-links-container-box gold-box' onClick={() => { scrollToSection("AwardsDesktop"); }} id='awardsNavItem-desktop'>
                        Awards
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesktopNavBar