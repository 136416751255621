import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { participant_png, state_topper_png, class_topper_png, scholarship_png } from '../../../../../assets';
import { participant_webp, state_topper_webp, class_topper_webp, scholarship_webp } from '../../../../../assets';
import { tile_mobile_png, tile_mobile_webp } from '../../../../../assets';
import './mobileAwards.css'
import '../awards.css'

const MobileAwards = () => {
    const titleStyleMobile = {
        fontSize: '8vw',
        margin: '0',
        marginTop: '0vw',
        marginBottom: '3vh',
        padding: '0'
    }

    function supportsWebP() {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const result = (img.width > 0) && (img.height > 0);
                resolve(result);
            };
            img.onerror = () => resolve(false);
            img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
        });
    }

    const [backgroundMobile, setBackgroundMobile] = useState('');

    useEffect(() => {
        supportsWebP().then(supported => {
            if (supported) {
                setBackgroundMobile(tile_mobile_webp);
            } else {
                setBackgroundMobile(tile_mobile_png);
            }
        });
    }, []);

    const mobileStyle = {
        backgroundImage: `url(${backgroundMobile})`,
    };

    return (
        <div className="awards" id='AwardsMobile' style={mobileStyle}>
            <div className="mobile-awards-content">
                <h2 className="awards-title mobile-awards-title" style={titleStyleMobile}>
                    <div className='mobile-awards-title-content'>
                        Awards
                    </div>
                </h2>
                <div className='mobile-awards-container'>

                    <div className='mobile-awards-category'>
                        <div className='mobile-awards-category-title state-topper-category-title' id='MobileStateAwards'>
                            <div className='mobile-awards-category-title-text'>Outstanding Artwork</div>
                            <div className='mobile-awards-category-title-image mobile-state-topper-image'>
                                <picture>
                                    <source type="image/webp" srcSet={state_topper_webp} />
                                    <img src={state_topper_png} className='mobile-awards-title-image' alt="State Topper Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='mobile-awards-category-text' style={{ paddingBottom: '0' }}>
                            <div className='mobile-awards-scholarship-image'>
                                <picture>
                                    <source type="image/webp" srcSet={scholarship_webp} />
                                    <img src={scholarship_png} className='mobile-awards-scholarship-image' alt="Scholarship Text Background Image"></img>
                                </picture>
                                <div className='overlay-content'>
                                    <h3 className='mobile-awards-scholarship-text tinos-font'>Scholarship</h3>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-awards-category-text-normal' style={{ fontSize: '2.5vh', padding: '0.5vh 0' }}>&</div>
                        <div className='mobile-awards-category-text-main state'>Certificate of Excellence</div>
                    </div>
                    <div className='mobile-awards-category mobile-non-state-topper-padding'>
                        <div className='mobile-awards-category-title' id='MobileClassAwards'>
                            <div className='mobile-awards-category-title-text'>Good Artwork</div>
                            <div className='mobile-awards-category-title-image mobile-non-state-topper-image'>
                                <picture>
                                    <source type="image/webp" srcSet={class_topper_webp} />
                                    <img src={class_topper_png} className='mobile-awards-title-image' alt="Class Topper Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='mobile-awards-category-text'>
                            <div className='mobile-awards-category-text-main class gold'>Medal of Merit</div>
                            <div className='mobile-awards-category-text-normal' style={{ fontSize: '2.5vh', padding: '0' }}>&</div>
                            <div className='mobile-awards-category-text-main class'>Certificate of Merit</div>
                        </div>
                    </div>
                    <div className='mobile-awards-category mobile-non-state-topper-padding'>
                        <div className='mobile-awards-category-title' id='MobileParticipantAwards'>
                            <div className='mobile-awards-category-title-text'>All Participants</div>
                            <div className='mobile-awards-category-title-image mobile-non-state-topper-image'>
                            <picture>
                                    <source type="image/webp" srcSet={participant_webp} />
                                    <img src={participant_png} className='mobile-awards-title-image' alt="Participant Image"></img>
                                </picture>
                            </div>
                        </div>
                        <div className='mobile-awards-category-text'>
                            <div className='mobile-awards-category-text-main participation' style={{ marginTop: '2vh', marginBottom: '0' }}>Certificate of Participation</div>
                            <div className='mobile-awards-category-text-normal'>All participants will receive a Certificate of Participation, except those who are toppers.</div>
                        </div>
                    </div>
                </div>
                <div className='mobile-awards-guidelines-text'>
                    <p>Please review the <Link to={'/guidelines'} style={{ fontWeight: '800', padding: '0 0.25vh', pointerEvents: 'auto' }}>Guidelines</Link> to learn more about how the toppers are chosen.</p>
                </div>
            </div>
        </div>
    )
}

export default MobileAwards