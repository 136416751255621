import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useViewPortMobileCheck } from '../../hooks/ViewPortContext';

function SyllabusPageRedirect() {
    const isMobile = useViewPortMobileCheck();
    const navigate = useNavigate();
    const { competitionName, className } = useParams();
    useEffect(() => {
        if (competitionName === "handwriting") {
            isMobile ? navigate('/handwriting/classes') : navigate('/handwriting/syllabus/class-1');
        } else if (competitionName === "colouring") {
            isMobile ? navigate('/colouring/classes') : navigate('/colouring/syllabus/LKG');
        } else {
            isMobile ? navigate('/drawing/classes') : navigate('/drawing/syllabus/LKG');
        }
    }, [navigate]);

    return null;  // Render nothing.
}

export default SyllabusPageRedirect;
