import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { classes } from '../../../models/themes';
import { name_text_white_png, arrow_forward_png } from '../../../assets';
import { board, deco_1 } from '../../../assets';
import { handwriting_sheet_img_1, handwriting_sheet_img_2 } from '../../../assets';
import { sample_handwriting_sheet_1, sample_handwriting_sheet_2 } from '../../../assets';
import ImageLoader from '../../../utils/ImageLoader';
import './desktopSyllabus.css'
import { bounceContainerById } from '../../../animations/BounceComponent';

const DesktopSyllabus = () => {

    const { competitionName, className } = useParams();
    const classId = classes.findIndex((element) => element.name === (className.startsWith('c') ? className.match(/\d+/)[0] : className));
    const competitionId = competitionName === "drawing" ? 0 : competitionName === "colouring" ? 1 : 2;

    function getCompetitionName(competitionId, isFirstCaps, isFullCaps) {
        switch (competitionId) {
            case 0:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
            case 1:
                return (isFirstCaps ? "Colouring" : isFullCaps ? "COLOURING" : "colouring");
            case 2:
                return (isFirstCaps ? "Handwriting" : isFullCaps ? "HANDWRITING" : "handwriting");
            default:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
        }
    }

    function getPageColorCode(competitionId, top, bottom) {
        switch (competitionId) {
            case 0:
                return (top ? "#6899dd" : "#c7ddfc");
            case 1:
                return (top ? "#a8ee8c" : "#daffcb");
            case 2:
                return (top ? "#e8d470" : " #faf0c0");
            default:
                return (top ? "#6899dd" : "#c7ddfc");
        }
    }

    useEffect(() => {
        document.title = `Amazing Brushes - ${classId > 1 ? "Class-" + classes[classId].name : classes[classId].name} - ${getCompetitionName(competitionId, true, false)} Competition Syllabus`
    }, [classId]);

    const handleClassChange = (newClassId) => {
        // Replace current URL without adding to the history stack
        navigate(`/${getCompetitionName(competitionId, false, false)}/syllabus/${newClassId > 1 ? "class-" + classes[newClassId].name : classes[newClassId].name}`, { replace: true });
    };

    const navigate = useNavigate();
    const choosenClass = classes[classId];
    const [currentClass, setCurrentClass] = useState(choosenClass);
    const [scrollTriggeredClassId, setScrollTriggeredClassId] = useState(null);
    const scrollTimeoutRef = useRef(null);
    const centeringTimeoutRef = useRef(null);
    const fadeInTimeout = useRef(null);
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        scrollBasedOnClass(currentClass);
    }, []);

    const handleModalClassClick = (class_obj, bounceId) => {
        if (class_obj.id === currentClass.id) {
            return;
        }
        else {
            setCurrentClass(classes[class_obj.id]);
            sessionStorage.setItem('currentClassID', class_obj.id);
            handleClassChange(class_obj.id)
            setScrollTriggeredClassId(null);
            scrollBasedOnClass(classes[class_obj.id]);
        }
        bounceContainerById(bounceId)
    };

    const smoothScrollTo = (element, target, duration) => {
        const start = element.scrollTop;
        const change = target - start;
        const startTime = performance.now();

        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };

        const animateScroll = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = easeInOutQuad(Math.min(elapsed / duration, 1));

            element.scrollTop = start + progress * change;

            if (elapsed < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    };

    const calculateHeightFromAspectRatio = (element, ratio = 1) => {
        const width = element.offsetWidth;
        return width / ratio;
    };

    const centerElementInView = (element, container, expanded = false, byScrollAction = false) => {
        const ratio = expanded ? 1 : 4; // 1:1 for expanded, 4:1 for unexpanded
        const boxHeight = calculateHeightFromAspectRatio(element, ratio);
        const containerVisibleHeight = container.clientHeight;
        expanded ? smoothScrollTo(container, element.offsetTop - (3 * containerVisibleHeight / 7.35), byScrollAction ? 600 : 300)
            : container.scrollTop = element.offsetTop - (containerVisibleHeight / 2) - (boxHeight / 2);
    };

    const scrollBasedOnClass = (newClass, byScrollAction = false) => {
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }
        if (centeringTimeoutRef.current) {
            clearTimeout(centeringTimeoutRef.current);
        }
        const container = document.getElementById("ModalClassList");
        const targetIndex = classes.indexOf(newClass);

        if (targetIndex === classes.length) {
            container.scrollTop = container.scrollHeight;

            centeringTimeoutRef.current = setTimeout(() => {
                container.scrollTop = container.scrollHeight;
            }, 1100);
        } else if (targetIndex > 0) {
            const currentBox = document.getElementById(`ClassContainer${classes[targetIndex].id}`);
            if (currentBox && byScrollAction) {
                centerElementInView(currentBox, container); // by default, this will center the unexpanded box
            }
        } else {
            container.scrollTop = 0;
        }

        centeringTimeoutRef.current = setTimeout(() => {
            const expandedBox = document.getElementById(`ClassContainer${newClass.id}`);
            if (expandedBox) {
                centerElementInView(expandedBox, container, true, true); // setting expanded to true
            }
        }, byScrollAction ? 1100 : 350);

        if (byScrollAction) {
            const delay = 800;
            scrollTimeoutRef.current = setTimeout(() => {
                setScrollTriggeredClassId(null);
            }, delay);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
                e.preventDefault();
                if (currentClass.id <= 11) {
                    setCurrentClass(classes[currentClass.id + 1]);
                    sessionStorage.setItem('currentClassID', currentClass.id + 1);
                    handleClassChange(currentClass.id + 1)
                    scrollBasedOnClass(classes[currentClass.id + 1])
                }
            }
            else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
                e.preventDefault();
                if (currentClass.id > 1) {
                    setCurrentClass(classes[currentClass.id - 1]);
                    sessionStorage.setItem('currentClassID', currentClass.id - 1);
                    handleClassChange(currentClass.id - 1)
                    scrollBasedOnClass(classes[currentClass.id - 1])
                }
            }

        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            // Cleanup event listener on component unmount
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentClass]);

    function fadeIn() {
        const container = document.getElementById("ThemeDetailsForClass");
        if (!container) return;
        container.classList.add('full-opacity');
    }

    function refreshImageContainer() {
        const container = document.getElementById('modal-competition-colouring-image');
        if (!container) return;
        // Clone the container and replace the old one with the clone
        const newContainer = container.cloneNode(true);
        container.parentNode.replaceChild(newContainer, container);
    }

    useEffect(() => {
        // Triggered every time the category changes
        console.log("Triggered")
        const themeContainer = document.getElementById("ThemeDetailsForClass");
        if (!themeContainer) return;
        themeContainer.classList.remove('full-opacity');
        setShowSpinner(true);
        if (fadeInTimeout.current)
            clearTimeout(fadeInTimeout.current);
        fadeInTimeout.current = setTimeout(() => {
            fadeIn();
            setShowSpinner(false);
        }, !scrollTriggeredClassId ? 350 : 750);

        const handwritingListContainer = document.getElementById("DesktopHandwritingList")
        if(!handwritingListContainer) return;
        handwritingListContainer.scrollTop = 0;
    }, [currentClass]);

    return (

        <div className="desktop-themes-modal" style={{ '--top-color': getPageColorCode(competitionId, true, false), '--bottom-color': getPageColorCode(competitionId, false, true) }}>
            <div className="desktop-themes-modal-content">

                <div className='modal-top-row'>
                    <div className='desktop-modal-logo'>
                        <img src={name_text_white_png} className='desktop-modal-logo-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                    </div>
                    <div className='modal-classes-list' id='ModalClassList'>
                        {classes.map(class_obj => (
                            competitionId == 2 && class_obj.id <= 1 ? null : (
                                <div
                                    key={class_obj.id}
                                    className={`class-container ${currentClass.id == class_obj.id ? "expanded" : ""} ${scrollTriggeredClassId == class_obj.id ? "scroll-triggered" : ""}`}
                                    id={`ClassContainer${class_obj.id}`}
                                    onClick={() => handleModalClassClick(class_obj)}
                                >
                                    <div className={`class-text ${class_obj.id <= 1 ? 'KG-class-text' : ''}`}>
                                        {class_obj.id > 1 ?
                                            <div className='class-title'>Class</div>
                                            :
                                            <></>}
                                        <div className='class-title-class-number'>
                                            {class_obj.name}
                                        </div>
                                    </div>
                                    {currentClass.number == class_obj.number ?
                                        <div className='class-image'>
                                            <ImageLoader
                                                baseID={`DesktopModalClassImage${class_obj.id}`}
                                                className={'modal-class-image'}
                                                allRes_png={class_obj.imagePng}
                                                allRes_webp={class_obj.imageWebp}
                                                fetchPriority={'high'}
                                            />
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            )
                        ))}
                    </div>

                </div>
                <div className='modal-content-row'>
                    <div className='modal-syllabus-top-section'>
                        <div className='modal-syllabus-top-section-deco'>
                            <img src={deco_1} className='modal-deco-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                        <div className='modal-competition-title-section'>
                            <div className='modal-competition-title'>
                                <img src={board} className='modal-competition-title-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                <div className='competition-name-overlay-text'>

                                    <div className='modal-competition-title-top'>
                                        {getCompetitionName(competitionId, false, true)}
                                    </div>
                                    <div className='modal-competition-title-bottom'>
                                        COMPETITION - 2024
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-syllabus-top-section-deco'>
                            <img src={deco_1} className='modal-deco-img-opposite' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                    </div>

                    <div className='modal-syllabus-container'>
                        {showSpinner && <div className="spinner" style={{ marginLeft: '50%', marginTop: '5%' }} />}
                        <div className='modal-syllabus-bottom-container' id='ThemeDetailsForClass' style={{ '--gradient-top-color': '#2ccd8a' }}>

                            <div className='modal-competition-themes'>

                                {competitionId === 0 ?
                                    <>
                                        <div className='modal-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}>Themes:</span> <span style={{ fontSize: '1.25vw', textDecoration: 'none' }}>(Choose only one of the following themes)</span></div>
                                        {classes[classId].drawingSyllabus.map(syllabus => (
                                            <>
                                                <div className='modal-competition-drawing-syllabus-item'>
                                                    <div className='modal-competition-syllabus-number-circle'>{syllabus.id + 1}</div>
                                                    <div className='modal-competition-drawing-syllabus-container-divider'>--</div>
                                                    <div className='modal-competition-drawing-syllabus-type'>{syllabus.category}</div>
                                                    <div className='modal-competition-drawing-syllabus-container-divider'>--</div>
                                                    <div className='modal-competition-drawing-syllabus-description'>
                                                        {syllabus.description}
                                                        {/* The task is to draw the choosen theme in the A4 sheet, that will be provided by us on the day of competition. */}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </>
                                        ))}
                                    </>

                                    : competitionId === 1 ?
                                        <>
                                            <div className='modal-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}>Themes:</span> <span style={{ fontSize: '1.25vw', textDecoration: 'none' }}>(Choose only one of the following themes)</span></div>
                                            <div className='modal-competition-colouring-syllabus-container'>
                                                {classes[classId].colouringSyllabus.map(syllabus => (
                                                    <div className='modal-competition-colouring-syllabus-item'>
                                                        <div className='modal-competition-colouring-syllabus-item-info'>
                                                            <div className='modal-competition-syllabus-number-circle'>{syllabus.id + 1}</div>
                                                            <div className='modal-competition-colouring-syllabus-description'>{syllabus.description}
                                                            </div>
                                                        </div>
                                                        <div className='modal-competition-colouring-image'>
                                                            <ImageLoader
                                                                baseID={`ColouringImage${syllabus.id}`}
                                                                className={'modal-display-colouring-img'}
                                                                allRes_png={[syllabus.colouringImagePng, syllabus.colouringImagePng, syllabus.colouringImagePng]}
                                                                allRes_webp={[syllabus.colouringImagePng, syllabus.colouringImagePng, syllabus.colouringImagePng]}
                                                                fetchPriority={'high'}
                                                            />
                                                        </div>
                                                        <a href={syllabus.colouringImagePng} download={`${className + "-colouring-image-" + (syllabus.id + 1)}.jpg`} className='modal-competition-colouring-image-download-link'>
                                                            <div className='modal-competition-colouring-image-download-link-text'>Download Image</div>
                                                            <div className='modal-competition-colouring-image-download-link-arrow'>
                                                                <img src={arrow_forward_png} className='download-image-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </>

                                        :
                                        <>
                                            <div className='modal-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}></span> <span style={{ fontSize: '1.25vw', textDecoration: 'none' }}>Download the provided handwriting sheet to practice all the exercises listed below. During the exam, these texts will be on your exam paper, and you will need to copy them onto the handwriting sheet.</span></div>
                                            <div className='modal-competition-handwriting-syllabus-container'>
                                                <div className='modal-competition-handwriting-syllabus-sheet-left'>
                                                    <div className='modal-competition-handwriting-sheet'>
                                                        <img src={classId <= 4 ? handwriting_sheet_img_1 : handwriting_sheet_img_2} className='modal-display-handwriting-img' alt="Colouring Image" fetchpriority="high"></img>
                                                    </div>
                                                    <a href={classId <= 4 ? sample_handwriting_sheet_1 : sample_handwriting_sheet_2} download={`${classId <= 4 ? "Class 1 to 3 " : "Class 4 to 12 "}Sample Handwriting Sheet`} className='modal-competition-handwriting-sheet-download-link'>
                                                        <div className='modal-competition-handwriting-sheet-download-link-text'>Download Sample Handwriting Practice Sheet</div>
                                                        <div className='modal-competition-handwriting-sheet-download-link-arrow'>
                                                            <img src={arrow_forward_png} className='download-image-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className='modal-competition-handwriting-syllabus-exercises-right'>
                                                    <div className='modal-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}>Exercises:</span></div>
                                                    <div className='modal-handwriting-exercises-list' id='DesktopHandwritingList'>
                                                        {classes[classId].handwritingSyllabus.map((syllabus, syllabusIndex) => (
                                                            <div key={syllabusIndex} className='handwriting-syllabus'>
                                                                <h3>({syllabus.description})</h3>
                                                                {syllabus.containsPhrases ? syllabus.textSet.map((textArray, textArrayIndex) => (
                                                                    <div className='modal-handwriting-exercise-set' key={textArrayIndex}>
                                                                        {textArray.map((text, textIndex) => (
                                                                            <div className='modal-handwriting-exercise' key={textIndex}>
                                                                                {text}
                                                                                <br />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )) : <></>}
                                                                {
                                                                    syllabus.containsPassage
                                                                        ?
                                                                        syllabus.passageSet.map((textArray, textArrayIndex) => (
                                                                            <div className='modal-handwriting-exercise-set' key={textArrayIndex}>

                                                                                {textArray.map((text, textIndex) => (

                                                                                    <div className='modal-handwriting-exercise' key={textIndex}>
                                                                                        <div className='modal-competition-themes-initial-text' style={{ paddingLeft: 0 }}>Passage {textIndex + 1}:</div>
                                                                                        {text}
                                                                                        <br />
                                                                                    </div>
                                                                                ))}
                                                                                <br />
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        <></>
                                                                }
                                                                {
                                                                    syllabus.containsPoemStory
                                                                        ?
                                                                        <>
                                                                            <div className='modal-competition-themes-initial-text' style={{ paddingLeft: 0 }}>Poem: {syllabus.poemTitle}</div>
                                                                            {syllabus.poem.map((textArray, textArrayIndex) => (
                                                                                <>

                                                                                    <div className='modal-handwriting-exercise-set' key={textArrayIndex}>
                                                                                        {textArray.map((text, textIndex) => (
                                                                                            <div className='modal-handwriting-exercise' style={{ paddingBottom: '0.25vw' }} key={textIndex}>
                                                                                                {text}
                                                                                                <br />
                                                                                            </div>
                                                                                        ))}
                                                                                        <br />
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                            {syllabus.story.map((textArray, textArrayIndex) => (
                                                                                <>
                                                                                    <div className='modal-competition-themes-initial-text' style={{ paddingLeft: 0 }}>Story: {syllabus.storyTitle}</div>
                                                                                    <div className='modal-handwriting-exercise-set' key={textArrayIndex}>
                                                                                        {textArray.map((text, textIndex) => (
                                                                                            <div className='modal-handwriting-exercise' key={textIndex}>
                                                                                                {text}
                                                                                                <br /><br />
                                                                                            </div>
                                                                                        ))}
                                                                                        <br />
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DesktopSyllabus