import React from 'react';
import { useViewPortMobileCheck } from '../../../hooks/ViewPortContext';
import DesktopInstructions from './desktop/DesktopInstructions';
import MobileInstructions from './mobile/MobileInstructions';

const Instructions = () => {
    const isMobile = useViewPortMobileCheck()

    return isMobile
    ? (
        <MobileInstructions />
    )
    : (
        <DesktopInstructions />
    )
}

export default Instructions