import React, { useEffect } from 'react';
import { useViewPortMobileCheck } from '../../../../hooks/ViewPortContext';
import { name_text_white_png } from '../../../../assets';
import './termsnconditions.css'

const TermsNConditions = () => {
    const isMobile = useViewPortMobileCheck()
    useEffect(() => {
        document.title = "Amazing Brushes - Terms & Conditions"
    }, []);
    const tncContentsStyle = {
        padding: isMobile ? "5vw" : "2vw",
        paddingTop: isMobile ? "8vw" : "5vw"
    }
    const bigHeadingStyle = {
        fontSize: isMobile ? "6vw" : "2.5vw",
        paddingBottom: isMobile ? "3vw" : "1vw"
    }
    const headingStyle = {
        fontSize: isMobile ? "4.5vw" : "1.75vw",
        paddingBottom: isMobile ? "3vw" : "1vw"
    }
    const smallHeadingStyle = {
        fontSize: isMobile ? "3.5vw" : "1.25vw",
        paddingBottom: isMobile ? "1vw" : "0.5vw"
    }
    const lastUpdateStyle = {
        fontSize: isMobile ? "3vw" : "1.25vw",
        paddingBottom: isMobile ? "1.5vw" : "0.75vw"
    }
    const normalStyle = {
        fontSize: isMobile ? "3vw" : "1.05vw",
    }
    const normalHeightStyle = {
        fontSize: isMobile ? "3.25vw" : "1.15vw",
        paddingTop: isMobile ? "1.5vw" : "0.25vw",
        paddingBottom: isMobile ? "0.25vw" : "0.25vw",
        paddingLeft: isMobile ? "3vw" : "2vw"
    }
    const boldHeightStyleMobile = {
        fontSize: "3.25vw",
        paddingLeft: "3vw",
        paddingBottom: "1.5vw",
        fontWeight: 600
    }
    const boldHeightStyleDesktop = {
        fontSize: "1.15vw",
        paddingBottom: "1.5vw",
        fontWeight: 600
    }
    const notePaddingStyle = {
        paddingRight: isMobile ? "5vw" : "2vw",
        paddingBottom: isMobile ? "2vw" : "0.5vw"
    }
    const batchPaddingStyle = {
        paddingLeft: isMobile ? "5vw" : "2vw",
        paddingBottom: isMobile ? "2vw" : "0.5vw"
    }
    const noteTextStyle = {
        fontWeight: 600,
        color: "red"
    }
    const boldStyle = {
        fontSize: isMobile ? "3vw" : "1.05vw",
    }
    const dividerStyle = {
        height: isMobile ? "0.3vw" : "0.075vw",
        marginTop: isMobile ? "6vw" : "1.75vw",
        marginBottom: isMobile ? "6vw" : "1.75vw",
    }
    const smallSubHeadingSpaceStyle = {
        height: isMobile ? "4.75vw" : "1vw",
    }
    const tncContainerStyle = {
        paddingTop: isMobile ? "7vh" : "3.5vw",
    }
    return (
        <div className='tnc-page'>
            <nav>
                <div className={`navbar-otherpages ${isMobile ? 'mobile' : ''}`}>
                    {isMobile ?
                        <div className='mobile-landingPage-logo'>
                            {/* <img src={amazing_brushes_logo} alt="Amazing Brushes Logo" fetchpriority="high"></img> */}
                            <img src={name_text_white_png} className='mobile-landingPage-navbar-name-text-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                        :
                        <div className='desktop-landingPage-navbar-logo'>
                            {/* <img src={amazing_brushes_logo} className='desktop-landingPage-navbar-logo-img' alt="Amazing Brushes Logo" fetchpriority="high"></img> */}
                            <img src={name_text_white_png} className='desktop-landingPage-navbar-name-text-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                    }
                </div>
            </nav>
            <main>
                <div className='tnc-content-container' style={tncContainerStyle}>
                    <div className='tnc-contents' id="TncContents" style={tncContentsStyle}>


                        <div className='big-heading-text' style={bigHeadingStyle}>
                            Terms & Conditions
                        </div>
                        <div className='bold-height-text' style={lastUpdateStyle}>
                            Last Updated:
                            <span className='normal-text' style={normalStyle}> July 01, 2024 </span>
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            Welcome to the Amazing Brushes, a unit of Zimba Publication. Zimba Publication is a private enterprise, registered under the Government of India's Ministry of Micro, Small and Medium Enterprises (MSME).
                            Before you participate in this competition, please carefully read and
                            understand the following terms and conditions that govern your involvement.
                            By enrolling in the competition, you agree to be bound by these terms and conditions.
                            Failure to comply with these terms and conditions may result in disqualification from the competition.
                        </div>
                        <br></br>
                        <div className='normal-text' style={normalStyle}>
                            Throughout these terms,
                        </div>
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>"we", "us", "our" </span>
                            - shall refer to Zimba Publication;
                        </div>
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>
                                "you," "your," "yours," and "yourself"</span>
                            - shall refer to the teacher, principal, or the head of the school,
                            competition coordinator, or management of the school that is accessing our services.
                            However, when we mention "student/participant" or "parent/legal guardian" of the
                            student participating in this competition, it will be explicitly stated as such.
                        </div>
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>"services", "Zimba Publication Services" </span>
                            - shall include all services extended (or) to be extended by Zimba Publication as a group
                            including but not limited to website, Amazing Brushes, including others;
                        </div>
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>"website" </span>
                            - shall refer to www.amazingbrushes.com, which is registered by Zimba Publication
                            and used as a medium to communicate and inform users of services related
                            to Amazing Brushes provided by us.
                        </div>
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>"awards" </span>
                            - specifically pertains to the following items provided by us:
                            Scholarship, Certificate of Excellence, Medal of Merit, Certificate of Merit,
                            and Certificate of Participation.
                        </div>
                        {isMobile ? <br></br> : <></>}
                        {isMobile ? <br></br> : <></>}
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>"guidelines" </span>
                            - shall refer to the guidelines provided in the "Guidelines" section of our website.
                            These guidelines encompass the judging criteria and the rules governing the
                            allocation of awards for best and outstanding artworks.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            1. Eligibility
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>1.1 </span>
                            This competition is open to students from LKG(Junior KG) to Class 12
                            who wish to participate through their respective schools only.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>1.2 </span>
                            Students must contact their class teacher for enrollment
                            and complete the registration process through their schools.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            2. Registration
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>2.1 </span>
                            Schools are responsible for collecting and
                            submitting the registration fee on behalf of their students.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>2.2 </span>
                            The registration fee is non-refundable, regardless of whether
                            the participant eventually takes part in the competition or not.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>2.3 </span>
                            Schools must submit the registration fee at least 10 days
                            before the scheduled day of the competition to ensure timely delivery of Drawing and Colouring sheets.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            3. Placing an Order
                        </div>
                        <div className='small-heading-text' style={smallHeadingStyle}>
                            3.1 Order Form
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            Schools can place their orders by sending a
                            <span className='bold-text' style={boldStyle}> CLEAR PHOTO </span>
                            of their
                            <span className='bold-text' style={boldStyle}> FILLED ORDER FORM </span>
                            with
                            <span className='bold-text' style={boldStyle}> SCHOOL SEAL </span>
                            and
                            <span className='bold-text' style={boldStyle}> SIGNATURE </span>
                            (Principal / Headmaster / Headmistress) to the WhatsApp number provided in the
                            cover letter sent to your school.
                        </div>
                        <div style={smallSubHeadingSpaceStyle}></div>
                        <div className='small-heading-text' style={smallHeadingStyle}>
                            3.2 Payment Methods
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            Payments can be done through Paytm, PhonePe, or Google Pay to the number mentioned
                            in the cover letter, otherwise bank transfer can be done to the
                            bank account mentioned in the cover letter.
                        </div>
                        <div style={smallSubHeadingSpaceStyle}></div>
                        <div style={notePaddingStyle}>
                            <div className='normal-height-text' style={normalHeightStyle}>
                                <span style={noteTextStyle}>NOTE : </span>{isMobile ? <></> :
                                    <span style={boldHeightStyleDesktop}>
                                        Immediately after making the payment, please share the payment screenshot
                                        (or) confirmation to our Whatsapp Number in order to avoid unnecessary
                                        delays and other issues.</span>}
                            </div>
                            {isMobile ? <div style={boldHeightStyleMobile}>
                                Immediately after making the payment, please share the payment screenshot
                                (or) confirmation to our Whatsapp Number in order to avoid unnecessary
                                delays and other issues.</div> : <></>}
                        </div>
                        <div style={smallSubHeadingSpaceStyle}></div>
                        <div className='small-heading-text' style={smallHeadingStyle}>
                            3.3 Date of Order
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            The date on which your payment is credited into our account will only
                            be taken as the
                            <span className='bold-text' style={boldStyle}> Date of Order </span>
                            . We will not be responsible for any delay or error occuring during
                            the bank or UPI transaction.
                        </div>
                        <div style={smallSubHeadingSpaceStyle}></div>
                        <div className='small-heading-text' style={smallHeadingStyle}>
                            3.4 Order Refusal
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            Orders without payment will not be accepted.
                            Minimum or Sample orders will not be entertained.
                            We reserve the right to accept or refuse any order that we deem irrelevant or insufficient.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            4. Drawing and Colouring Sheets
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>4.1 </span>
                            Drawing and Colouring Sheets will be provided to the participants on the
                            day of the competition at their respective schools.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>4.2 </span>
                            Participants must use the provided sheet for their artwork submissions.
                            No other papers will be accepted.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>4.3 </span>
                            The school bears the responsibility of promptly collecting and
                            submitting the art sheets immediately following the conclusion of the art competition.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            5. Judging and Awards
                        </div>
                        <div className='normal-text' style={normalStyle}>

                            <span className='bold-text' style={boldStyle}>5.1 </span>
                            The selection of the best and outstanding artworks will be done by our panel of judges.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>5.2 </span>
                            The decision of the judges is final, and no appeals or disputes will be entertained.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>5.3 </span>
                            The names of the students whose artwork is recognised as best and outstanding artworks, selected by us will be communicated to the respective schools.
                            The date of the announcement of best and outstanding artworks will be decided based on the submission of the
                            answer sheets from all participating schools. We reserve the right to determine
                            the announcement date accordingly.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>5.4 </span>
                            The responsibility of informing the students about best and outstanding artworks selected by our judges lies with their respective schools.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>5.5 </span>
                            The awards will be dispatched to the respective schools,
                            and it will be the schools' responsibility to distribute them to the student who created outstanding artwork.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>5.6 </span>
                            All participants, including schools and students, must thoroughly review the
                            judging guidelines outlined in the 'Guidelines' section of our website.
                            These guidelines will be the basis for judging and the allocation of awards.
                            Any disputes related to these guidelines will not be entertained.
                            By participating in the Amazing Brushes Art Competition, you acknowledge
                            and agree to abide by the judging guidelines and the rules for the
                            allocation of awards as stated in the 'Guidelines' section of our website.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            6. Scholarships
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>6.1 </span>
                            The amount and terms of the scholarship will be determined at the sole discretion of the competition organizers. These details will be communicated in writing to the respective educational institutions.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.2 </span>
                            The one-time scholarship fund will be disbursed directly to the educational institution where the student who created outstanding artwork is currently enrolled, provided that the institution meets all the necessary legal and regulatory requirements.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.3 </span>
                            The educational institution shall apply the scholarship funds towards the tuition of the student who created outstanding artworkor other approved educational expenses in accordance with the laws and regulations governing such funds.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.4 </span>
                            The details regarding the application of the scholarship, including its disbursal method and usage, will be documented in writing and communicated to the respective educational institutions, the winning students.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.5 </span>
                            It is the responsibility of the educational institution to ensure the scholarship funds are applied appropriately towards the student's educational expenses. The competition organizers assume no responsibility for the misuse or misapplication of the scholarship funds.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.6 </span>
                            The scholarships are strictly non-transferable and are intended solely for the benefit of the student who created outstanding artwork. Any attempt to transfer, assign or sell the scholarship may result in the immediate revocation of the scholarship.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>6.7 </span>
                            In the event of any dispute or non-compliance with the terms and conditions of this competition by the student who created outstanding artwork, the parents or guardians, or the educational institution, the competition organizers reserve the right to withdraw the scholarship offer. This decision is at the sole discretion of the competition organizers and will be communicated in writing to all involved parties.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            7. Copyright and Usage
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>7.1 </span>
                            By participating in the competition, all participants grant us
                            the right to use their submitted artwork for promotional and marketing purposes,
                            without any additional compensation.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>7.2 </span>
                            By participating in the competition, students and parents/legal guardians of these students,
                            grant us the right to use any photos taken during the awards distribution
                            for promotional and marketing purposes. We reserve the right to use these photos
                            without seeking any additional permission from the student or parents/legal guardians of the student.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            8. Disqualification
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>8.1 </span>
                            Any participant found engaging in plagiarism, cheating, or any form of
                            unethical behavior will be immediately disqualified from the competition.
                            <br></br>
                            <br></br>
                            <span className='bold-text' style={boldStyle}>8.2 </span>
                            The decision to disqualify any participant will be made solely by the competition organizers.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            9. Indemnity
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>9.1 </span>
                            By participating in the competition, all participants and their parents/legal guardians
                            agree to indemnify and hold Zimba Publication, its affiliates, and partners
                            harmless from any claims, damages, or liabilities arising out of their participation.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            10. Governing Law and Venue
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>10.1 </span>
                            The Terms shall be governed by and construed in accordance with the
                            laws of India, without regard to conflict of law principles.
                            Further, the Terms shall be subject to the exclusive jurisdiction of the
                            competent courts located in Dharmapuri, Tamilnadu and the Schools
                            hereby accede to and accept the jurisdiction of such courts.
                        </div>
                        <div className='tnc-divider-line' style={dividerStyle}></div>

                        {/* /////////////////////////////////////////////////////////////////////////*/}

                        <div className='heading-text' style={headingStyle}>
                            11. Update to Terms and Conditions
                        </div>
                        <div className='normal-text' style={normalStyle}>
                            <span className='bold-text' style={boldStyle}>11.1 </span>
                            We reserve the right to update this Terms & Conditions from time to time.
                            When we do, we will revise the "Last Updated" at the top of this Terms & Conditions.
                            We encourage you to check this page periodically for any updates.
                            If you continue to use our service following the posting of an updated version of this
                            Terms & Conditions, we will treat your continued use as acceptance of the updated version.
                        </div>
                        <div style={smallSubHeadingSpaceStyle}></div>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default TermsNConditions