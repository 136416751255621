import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useViewPortMobileCheck, useViewPortRatio } from '../../../../hooks/ViewPortContext';
import './gallery.css'
import { mobileBackgroundImage_png, mobileBackgroundImage_low_res_png, mobileBackgroundImage_very_low_res_png } from '../../../../assets';
import { backgroundImage_png, backgroundImage_low_res_png, backgroundImage_very_low_res_png } from '../../../../assets';
import { mobileBackgroundImage_webp, mobileBackgroundImage_low_res_webp, mobileBackgroundImage_very_low_res_webp } from '../../../../assets';
import { backgroundImage_webp, backgroundImage_low_res_webp, backgroundImage_very_low_res_webp } from '../../../../assets';
import { arrow_forward_png, arrow_back_png } from '../../../../assets';
import ImageLoader from '../../../../utils/ImageLoader';
import { gallery } from '../../../../models/gallery';

const Gallery = () => {
    const isMobile = useViewPortMobileCheck();
    const displayRatio = useViewPortRatio();

    function isDisplayTablet() {
        return displayRatio >= 0.66;
    }

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const totalDivs = 10;
    const [currentIndex, setCurrentIndex] = useState(0);

    const moveLeft = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalDivs) % totalDivs);
    };

    const moveRight = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalDivs);
    };


    return isMobile
        ? (
            <div className='gallery' id='GalleryMobile'>
                <div className='gallery-contents gallery-contents-mobile'>
                    <div className='mobile-gallery-title'>
                        <h1 className='mobile-gallery-title-text'>
                            Gallery
                        </h1>
                        <h3 className='mobile-gallery-title-subtext'>
                            Showcasing Exceptional Artwork by Students from Participated Schools
                        </h3>
                        <div className={`mobile-info-box-top visible`}>
                            Independence Day Celebration, 2023
                        </div>
                    </div>
                    <div className="mobile-gallery-showcase">
                        <div className="mobile-container">
                            <div onClick={moveLeft}className='mobile-gallery-button-outer-shell'>
                                <img src={arrow_back_png} className='mobile-gallery-button'></img> {/* Left arrow icon */}
                            </div>
                            <div className="mobile-divs-container" style={{ 'height': (isDisplayTablet() ? '100vw' : '65vh') }}>
                                {gallery.map((div, index) => {
                                    let position = (index - currentIndex + totalDivs) % totalDivs;
                                    return (
                                        <div key={div.id} className={`mobile-div-item position-${position}`} style={{ 'height': (isDisplayTablet() ? '80vw' : '65vh') }}>



                                            <div className={`mobile-div-item-img position-${position}`}
                                                style={{ '--middle-color1': div.middleColors[0], '--middle-color2': div.middleColors[1], '--middle-border-color': div.middleColors[2] }}>
                                                <img src={div.imageSrc} /></div>
                                            <div className={`mobile-info-box-bottom ${position === 3 ? 'visible' : ''}`}
                                                style={{ 'bottom': (isDisplayTablet() ? '0%' : '20%'), '--bottom-color1': div.bottomColors[0], '--bottom-color2': div.bottomColors[1], '--bottom-border-color': div.bottomColors[2] }}>
                                                <div className='mobile-info-box-bottom-content'>
                                                    <div className='mobile-info-box-bottom-text-box mobile-info-box-bottom-name'>{div.name}</div>
                                                    <div className='mobile-info-box-bottom-text-box mobile-info-box-bottom-class'>{div.class}</div>
                                                    <div className='mobile-info-box-bottom-text-box mobile-info-box-bottom-school'>{div.school}</div>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}
                            </div>
                            <div onClick={moveRight} className='mobile-gallery-button-outer-shell mobile-rotate-gallery-button-outer-shell'>
                                <img src={arrow_back_png} className='mobile-gallery-button'></img> {/* Right arrow icon */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
        : (
            <div className='gallery' id='GalleryDesktop'>
                <div className='gallery-contents gallery-contents-desktop'>
                    <div className='gallery-title'>
                        <h1 className='gallery-title-text'>
                            Gallery
                        </h1>
                        <div className='gallery-title-subtext'>
                            <h3 style={{ fontSize: '1.25vw', fontWeight: '500', margin: 0, padding: 0 }}>
                                Showcasing Exceptional Artwork by Students from Participated Schools
                            </h3>
                        </div>
                    </div>
                    <div className="gallery-showcase">
                        <div className="container">
                            <img onClick={moveLeft} src={arrow_back_png} className='gallery-button'></img> {/* Left arrow icon */}
                            <div className="divs-container">
                                {gallery.map((div, index) => {
                                    let position = (index - currentIndex + totalDivs) % totalDivs;
                                    return (
                                        <div key={div.id} className={`div-item position-${position}`}>

                                            <div className={`info-box-top ${position === 3 ? 'visible' : ''}`}
                                                style={{ '--top-color1': div.topColors[0], '--top-color2': div.topColors[1], '--top-border-color': div.topColors[2] }}>
                                                {div.event}</div>

                                            <div className={`div-item-img position-${position}`}
                                                style={{ '--middle-color1': div.middleColors[0], '--middle-color2': div.middleColors[1], '--middle-border-color': div.middleColors[2] }}>
                                                <img src={div.imageSrc} /></div>
                                            {/* <div className='gallery-image-overlay'>Sample Image, will be replace by Original Artwork</div> */}
                                            <div className={`info-box-bottom ${position === 3 ? 'visible' : ''}`}
                                                style={{ '--bottom-color1': div.bottomColors[0], '--bottom-color2': div.bottomColors[1], '--bottom-border-color': div.bottomColors[2] }}>
                                                <div className='info-box-bottom-content'>
                                                    <div className='info-box-bottom-row-1'>
                                                        <div className='info-box-bottom-text-box info-box-bottom-name'>{div.name}</div>
                                                        <div className='info-box-bottom-text-box info-box-bottom-class'>{div.class}</div>
                                                    </div>
                                                    <div className='info-box-bottom-text-box info-box-bottom-school'>{div.school}</div>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}
                            </div>
                            <img onClick={moveRight} src={arrow_back_png} className='gallery-button rotate-gallery-button'></img> {/* Right arrow icon */}
                        </div>
                    </div>

                </div>
            </div>
        );
}

export default Gallery