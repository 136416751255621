import { Navigate, useParams } from 'react-router-dom';
import SyllabusPage from './SyllabusPage';

function ValidateSyllabusPage() {
    const { competitionName, className } = useParams();

    const formattedCompetitionName = competitionName.toLowerCase();
    const formattedClassName = className.toLowerCase();

    const classNameList = ['lkg', 'ukg', 'class-1', 'class-1', 'class-2', 'class-3', 'class-4', 'class-5', 'class-6', 'class-7', 'class-8', 'class-9', 'class-10', 'class-11', 'class-12']
    const competitionNameList = ['handwriting', 'colouring', 'drawing']

    function isValidClassName(name) {
        return classNameList.includes(name) && competitionNameList.includes(formattedCompetitionName) && (formattedCompetitionName === competitionNameList[0] ? classNameList.slice(2).includes(name) : true)
    }

    // Perform validation here (e.g., check if the parameters are correct)
    if (!competitionNameList.includes(formattedCompetitionName) || !isValidClassName(formattedClassName)) {
        if (competitionNameList.includes(formattedCompetitionName)) {
            console.log(competitionName)
            const link = "/"+ formattedCompetitionName + "/syllabus" + (formattedCompetitionName === competitionNameList[0] ? "/class-1": "/LKG")
            return <Navigate to={link} replace />;
        }
        return <Navigate to="/" replace />;
    }

    // If valid, render the SyllabusPage
    return <SyllabusPage />;
}

export default ValidateSyllabusPage;
