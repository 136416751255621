import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DelayedLink } from '../../../hooks/DelayedLink';
import { classes } from '../../../models/themes';
import { name_text_white_png, deco_1, arrow_forward_png, handwriting_sheet_img_1, handwriting_sheet_img_2 } from '../../../assets';
import { sample_handwriting_sheet_1, sample_handwriting_sheet_2 } from '../../../assets';
import './mobileSyllabus.css'
import { bounceContainerById } from '../../../animations/BounceComponent';
import ImageLoader from '../../../utils/ImageLoader';

const MobileSyllabus = () => {

    const { competitionName, className } = useParams();
    const classId = classes.findIndex((element) => element.name === (className.startsWith('c') ? className.match(/\d+/)[0] : className));
    const competitionId = competitionName === "drawing" ? 0 : competitionName === "colouring" ? 1 : 2;

    function getCompetitionName(competitionId, isFirstCaps, isFullCaps) {
        switch (competitionId) {
            case 0:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
            case 1:
                return (isFirstCaps ? "Colouring" : isFullCaps ? "COLOURING" : "colouring");
            case 2:
                return (isFirstCaps ? "Handwriting" : isFullCaps ? "HANDWRITING" : "handwriting");
            default:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
        }
    }

    function getPageColorCode(competitionId, top, bottom) {
        switch (competitionId) {
            case 0:
                return (top ? "#6899dd" : "#c7ddfc");
            case 1:
                return (top ? "#a8ee8c" : "#daffcb");
            case 2:
                return (top ? "#e8d470" : " #faf0c0");
            default:
                return (top ? "#6899dd" : "#c7ddfc");
        }
    }

    useEffect(() => {
        document.title = `Amazing Brushes - ${classId > 1 ? "Class-" + classes[classId].name : classes[classId].name} - ${getCompetitionName(competitionId, true, false)} Competition Syllabus`
    }, []);

    return (
        <div className="mobile-syllabus">
            <div className="mobile-syllabus-content">
                <div className='mobile-syllabus-top-container'>
                    <div className='mobile-syllabus-top-left-container'>
                        <div className='mobile-syllabus-top-row' style={{ '--competition-top-colour': getPageColorCode(competitionId, true, false) }}>

                            <div className='mobile-syllabus-text-logo'>
                                <img src={name_text_white_png} className='mobile-syllabus-text-logo-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>


                        </div>
                        <div className='mobile-syllabus-title-container' style={{ '--competition-bottom-colour': getPageColorCode(competitionId, false, true) }}>

                            <div className='mobile-syllabus-competition-title-section'>
                                <div className='mobile-syllabus-competition-title'>
                                    <div className='mobile-syllabus-competition-title-top'>
                                        {getCompetitionName(competitionId, true, false)}
                                    </div>
                                    <div className='mobile-syllabus-competition-title-bottom'>
                                        {classId > 1 ? 'Class - ' + (classId - 1) : className}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='mobile-syllabus-top-right-container'>
                        <div className='mobile-syllabus-page-class-image-container'>
                            <ImageLoader
                                baseID={`MobileClassImage${classes[classId].id}`}
                                className={'mobile-class-image'}
                                allRes_png={classes[classId].imagePng}
                                allRes_webp={classes[classId].imageWebp}
                                fetchPriority={'high'}
                            />
                        </div>
                    </div>
                </div>
                <div className='mobile-syllabus-bottom-container' id='ThemeDetailsForClass'>

                    {competitionId === 0 ?
                        <>
                            <div className='mobile-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}>Themes</span> <br /><span style={{ fontSize: '2vh', textDecoration: 'none' }}>(Choose only one of the following themes)</span></div>
                            {classes[classId].drawingSyllabus.map(syllabus => (
                                <>
                                    <div className='mobile-competition-drawing-syllabus-item'>
                                        <div className='mobile-competition-syllabus-top-row'>
                                            <div className='mobile-competition-syllabus-number-circle'>{syllabus.id + 1}</div>
                                            <div className='mobile-competition-drawing-syllabus-container-divider'>--</div>
                                            <div className='mobile-competition-drawing-syllabus-type'>{syllabus.category}</div>
                                        </div>
                                        <div className='mobile-competition-drawing-syllabus-description'>
                                            {syllabus.description}
                                            {/* The task is to draw the choosen theme in the A4 sheet, that will be provided by us on the day of competition. */}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </>

                        : competitionId === 1 ?
                            <>
                                <div className='mobile-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}>Themes</span> <br /><span style={{ fontSize: '2vh', textDecoration: 'none' }}>(Choose only one of the following themes)</span></div>
                                <div className='mobile-competition-colouring-syllabus-container'>
                                    {classes[classId].colouringSyllabus.map(syllabus => (
                                        <div className='mobile-competition-colouring-syllabus-item'>
                                            <div className='mobile-competition-colouring-syllabus-item-info'>
                                                <div className='mobile-competition-syllabus-number-circle mobile-competition-syllabus-number-circle-colouring '>{syllabus.id + 1}</div>
                                                <div className='mobile-competition-colouring-syllabus-description'>{syllabus.description}
                                                </div>
                                            </div>
                                            <div className='mobile-competition-colouring-image'>
                                                <ImageLoader
                                                    baseID={`MobileColouringImage${syllabus.id}`}
                                                    className={'mobile-display-colouring-img'}
                                                    allRes_png={[syllabus.colouringImagePng, syllabus.colouringImagePng, syllabus.colouringImagePng]}
                                                    allRes_webp={[syllabus.colouringImagePng, syllabus.colouringImagePng, syllabus.colouringImagePng]}
                                                    fetchPriority={'high'}
                                                />
                                            </div>
                                            <a href={syllabus.colouringImagePng} download={`${className + "-colouring-image-" + (syllabus.id + 1)}.jpg`} className='mobile-competition-colouring-image-download-link'>
                                                <div className='mobile-competition-colouring-image-download-link-text'>Download Image</div>
                                                <div className='mobile-competition-colouring-image-download-link-arrow'>
                                                    <img src={arrow_forward_png} className='mobile-download-image-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </>

                            :
                            <>

                                <div className='mobile-competition-handwriting-syllabus-container'>

                                    <div className='mobile-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}></span> <span style={{ fontSize: '2vh', textDecoration: 'none' }}>During the exam, these texts will be on your exam paper, and you will need to copy them onto the handwriting sheet.</span></div>
                                    <div className='mobile-competition-handwriting-syllabus-exercises-right'>
                                        <div className='mobile-competition-themes-handwriting-sub-title-text' style={{ paddingLeft: '1vw' }}><span style={{ fontSize: '4vh', textDecoration: 'underline' }}>Exercises:</span></div>
                                        <div className='mobile-handwriting-exercises-list'>
                                            {classes[classId].handwritingSyllabus.map((syllabus, syllabusIndex) => (
                                                <div key={syllabusIndex} className='handwriting-syllabus'>
                                                    <div style={{ fontSize: '2.25vh', textDecoration: 'none', paddingBottom: '2.5vh' }}>({syllabus.description})</div>
                                                    {syllabus.containsPhrases ? syllabus.textSet.map((textArray, textArrayIndex) => (
                                                        <div className='mobile-handwriting-exercise-set' key={textArrayIndex}>
                                                            {textArray.map((text, textIndex) => (
                                                                <div className='mobile-handwriting-exercise' key={textIndex}>
                                                                    {text}
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )) : <></>}
                                                    {
                                                        syllabus.containsPassage
                                                            ?
                                                            syllabus.passageSet.map((textArray, textArrayIndex) => (
                                                                <div className='mobile-handwriting-exercise-set' key={textArrayIndex}>

                                                                    {textArray.map((text, textIndex) => (

                                                                        <div className='mobile-handwriting-exercise' key={textIndex}>
                                                                            <div className='mobile-competition-themes-handwriting-sub-title-text' style={{ paddingLeft: 0 }}>Passage {textIndex + 1}:</div>
                                                                            {text}
                                                                            <br />
                                                                        </div>
                                                                    ))}
                                                                    <br />
                                                                </div>
                                                            ))
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        syllabus.containsPoemStory
                                                            ?
                                                            <>
                                                                <div className='mobile-competition-themes-handwriting-sub-title-text' style={{ paddingLeft: 0 }}>Poem: {syllabus.poemTitle}</div>
                                                                {syllabus.poem.map((textArray, textArrayIndex) => (
                                                                    <>

                                                                        <div className='mobile-handwriting-exercise-set' key={textArrayIndex}>
                                                                            {textArray.map((text, textIndex) => (
                                                                                <div className='mobile-handwriting-exercise' style={{ paddingBottom: '0.25vw' }} key={textIndex}>
                                                                                    {text}
                                                                                    <br />
                                                                                </div>
                                                                            ))}
                                                                            <br />
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                {syllabus.story.map((textArray, textArrayIndex) => (
                                                                    <>
                                                                        <div className='mobile-competition-themes-handwriting-sub-title-text' style={{ paddingLeft: 0 }}>Story: {syllabus.storyTitle}</div>
                                                                        <div className='mobile-handwriting-exercise-set' key={textArrayIndex}>
                                                                            {textArray.map((text, textIndex) => (
                                                                                <div className='mobile-handwriting-exercise' key={textIndex}>
                                                                                    {text}
                                                                                    <br /><br />
                                                                                </div>
                                                                            ))}
                                                                            <br />
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='mobile-competition-themes-initial-text'><span style={{ textDecoration: 'underline' }}></span> <span style={{ fontSize: '2.25vh', textDecoration: 'none' }}>Download the provided handwriting sheet to practice all the exercises listed below.<br /></span></div>
                                    <div className='mobile-competition-handwriting-syllabus-sheet-left'>
                                        <div className='mobile-competition-handwriting-sheet'>
                                            <img src={classId <= 4 ? handwriting_sheet_img_1 : handwriting_sheet_img_2} className='mobile-display-handwriting-img' alt="Colouring Image" fetchpriority="high"></img>
                                        </div>
                                        <a href={classId <= 4 ? sample_handwriting_sheet_1 : sample_handwriting_sheet_2} download={`${classId <= 4 ? "Class 1 to 3 " : "Class 4 to 12 "}Sample Handwriting Sheet`} className='mobile-competition-handwriting-sheet-download-link'>
                                            <div className='mobile-competition-handwriting-sheet-download-link-text'>Download Sample Handwriting Practice Sheet</div>
                                            <div className='mobile-competition-handwriting-sheet-download-link-arrow'>
                                                <img src={arrow_forward_png} className='mobile-download-image-link-arrow-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </>
                    }
                </div>

            </div>
        </div>
    )
}

export default MobileSyllabus