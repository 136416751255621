import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { instructions } from '../../../../models/themes';
import { name_text_white_png, arrow_forward_png } from '../../../../assets';
import { board, deco_1 } from '../../../../assets';
import { handwriting_sheet_img_1, handwriting_sheet_img_2 } from '../../../../assets';
import ImageLoader from '../../../../utils/ImageLoader';
import './desktopInstructions.css'
import { bounceContainerById } from '../../../../animations/BounceComponent';

const DesktopInstructions = () => {

    const { competitionName, className } = useParams();
    const competitionId = competitionName === "drawing" ? 0 : competitionName === "colouring" ? 1 : 2;

    function getCompetitionName(competitionId, isFirstCaps, isFullCaps) {
        switch (competitionId) {
            case 0:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
            case 1:
                return (isFirstCaps ? "Colouring" : isFullCaps ? "COLOURING" : "colouring");
            case 2:
                return (isFirstCaps ? "Handwriting" : isFullCaps ? "HANDWRITING" : "handwriting");
            default:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
        }
    }

    function getPageColorCode(competitionId, top, bottom) {
        switch (competitionId) {
            case 0:
                return (top ? "#6899dd" : "#c7ddfc");
            case 1:
                return (top ? "#a8ee8c" : "#daffcb");
            case 2:
                return (top ? "#e8d470" : " #faf0c0");
            default:
                return (top ? "#6899dd" : "#c7ddfc");
        }
    }

    useEffect(() => {
        document.title = `Amazing Brushes - ${getCompetitionName(competitionId, true, false)} Instructions`
    }, []);

    return (

        <div className="desktop-instructions" style={{ '--top-color': getPageColorCode(competitionId, true, false), '--bottom-color': getPageColorCode(competitionId, false, true) }}>
            <div className="desktop-instructions-content">

                <div className='desktop-instructions-top-row'>

                    <div className='desktop-instructions-logo-column'>
                        <div className='desktop-instructions-logo'>
                            <img src={name_text_white_png} className='desktop-instructions-logo-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>

                    </div>
                    <div className='desktop-instructions-top-section'>
                        <div className='desktop-instructions-top-section-deco'>
                            <img src={deco_1} className='desktop-instructions-deco-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                        <div className='desktop-instructions-competition-title-section'>
                            <div className='desktop-instructions-competition-title'>
                                <img src={board} className='desktop-instructions-competition-title-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                                <div className='desktop-instructions-competition-name-overlay-text'>

                                    <div className='desktop-instructions-competition-title-top'>
                                        {getCompetitionName(competitionId, false, true)}
                                    </div>
                                    <div className='desktop-instructions-competition-title-bottom'>
                                        COMPETITION - 2024
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='desktop-instructions-top-section-deco'>
                            <img src={deco_1} className='desktop-instructions-deco-img-opposite' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                        </div>
                    </div>

                </div>
                <div className='desktop-instructions-content-row'>


                    <div className='desktop-instructions-container'>
                        <div className='desktop-instructions-bottom-container' id='ThemeDetailsForClass' style={{ '--gradient-top-color': '#2ccd8a' }}>
                            <div className='desktop-instructions-title'>
                                Instructions
                            </div>
                            <div className='desktop-instructions-item'>
                                <div className='modal-competition-syllabus-number-circle'>1</div>
                                <div className='desktop-instruction-text'><span style={{textDecoration: 'underline', fontWeight: 700}}>Competition Eligibility and Venue:</span> {instructions[competitionName][0].eligibilityvenue} </div>
                            </div>
                            <div className='desktop-instructions-item'>
                                <div className='modal-competition-syllabus-number-circle'>2</div>
                                <div className='desktop-instruction-text'><span style={{textDecoration: 'underline', fontWeight: 700}}>Task Description:</span> {instructions[competitionName][0].task}</div>
                            </div>
                            <div className='desktop-instructions-item'>
                                <div className='modal-competition-syllabus-number-circle'>3</div>
                                <div className='desktop-instruction-text'><span style={{textDecoration: 'underline', fontWeight: 700}}>Materials to be Used:</span> {instructions[competitionName][0].materials}</div>
                            </div>
                            <div className='desktop-instructions-item'>
                                <div className='modal-competition-syllabus-number-circle'>4</div>
                                <div className='desktop-instruction-text'><span style={{textDecoration: 'underline', fontWeight: 700}}>{competitionId === 2 ? 'Preparation Material:' : 'Theme Selection:'}</span> {instructions[competitionName][0].themeselection}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DesktopInstructions