import React, { useEffect } from 'react';
import './desktopGuidelines.css'
import { amazing_brushes_logo, name_text_white_png } from '../../../../assets';
import '../guidelines.css'

const DesktopGuidelines = () => {
    const titleStyleDesktop = {
        fontSize: '4vw'
    }
    useEffect(() => {
        document.title = `Amazing Brushes - Guidelines`
    }, []);

    return (
        <div className="guidelines" id='GuidelinesDesktop'>
            <nav>
                <div className='navbar-otherpages'>
                    <div className='desktop-landingPage-navbar-logo'>
                        {/* <img src={amazing_brushes_logo} className='desktop-landingPage-navbar-logo-img' alt="Amazing Brushes Logo" fetchpriority="high"></img> */}
                        <img src={name_text_white_png} className='desktop-landingPage-navbar-name-text-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                    </div>
                </div>
            </nav>

            <main>
                <div className="guidelines-content desktop-guidelines-content">
                    <h1 className="guidelines-title desktop-guidelines-title" style={titleStyleDesktop}>Guidelines</h1>
                    <div className='guidelines-container'>

                        <section>
                            <div className='guidelines-box class' >
                                <div className='guidelines-heading'>
                                    <div className='guidelines-number-circle-outside'>
                                        <div className='guidelines-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='guidelines-number'>1</div>
                                        </div>
                                    </div>
                                    <div className='guidelines-heading-text'>
                                        Medals and Certificate of Merit:
                                    </div>
                                </div>
                                <div className="table-container">
                                    <table className="custom-table">
                                        <tbody>
                                            <tr>
                                                <td>5-10 students per class</td>
                                                <td>1 Medal and 1 Certificate of Merit</td>
                                            </tr>
                                            <tr>
                                                <td>11-20 students per class</td>
                                                <td>2 Medals and 2 Certificates of Merit</td>
                                            </tr>
                                            <tr>
                                                <td>21-30 students per class</td>
                                                <td>3 Medals and 3 Certificates of Merit</td>
                                            </tr>
                                            <tr>
                                                <td>31+ students per class</td>
                                                <td>For each 10 students, 1 Medal and 1 Certificate of Merit.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='guidelines-detail'>
                                    If there are multiple sections in a class, for example Class 1 has three sections A, B and C, the above guidelines for
                                    Medals and Certificate of Merit per class will apply to each section.
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className='guidelines-box participation' >
                                <div className='guidelines-heading'>
                                    <div className='guidelines-number-circle-outside'>
                                        <div className='guidelines-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='guidelines-number'>2</div>
                                        </div>
                                    </div>
                                    <div className='guidelines-heading-text'>
                                        Certificate of Participation:
                                    </div>
                                </div>
                                <div className='guidelines-detail'>
                                    All participants will receive a Certificate of Participation, except those who are toppers.
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className='guidelines-box state' >
                                <div className='guidelines-heading'>
                                    <div className='guidelines-number-circle-outside'>
                                        <div className='guidelines-number-circle' style={{ marginLeft: '0' }}>
                                            <div className='guidelines-number'>3</div>
                                        </div>
                                    </div>
                                    <div className='guidelines-heading-text'>
                                        Scholarship and Certificate of Excellence:
                                    </div>
                                </div>
                                <div className='guidelines-detail'>
                                    Our esteemed panel of judges will select artwork (drawing, colouring & handwriting) which exhibits exemplary artistic excellence as Outstanding Artwork.
                                    Student who artwork is Outstanding, will be graciously awarded the Certificate of Excellence, along with a one-time scholarship. The scholarship amount will be determined by our judges, and their decision will be final.
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default DesktopGuidelines