import { imgLKG_png, imgUKG_png, img1_png, img2_png, img3_png, img4_png, img5_png, img6_png, img7_png, img8_png, img9_png, img10_png, img11_png, img12_png, very_low_res_img_png, very_low_res_img_webp } from '../assets';
import { imgLKG_low_png, imgUKG_low_png, img1_low_png, img2_low_png, img3_low_png, img4_low_png, img5_low_png, img6_low_png, img7_low_png, img8_low_png, img9_low_png, img10_low_png, img11_low_png, img12_low_png } from '../assets';

import { lkg_colouring_img_1_png, lkg_colouring_img_2_png, ukg_colouring_img_1_png, ukg_colouring_img_2_png } from '../assets';
import { c1_colouring_img_1_png, c1_colouring_img_2_png, c2_colouring_img_1_png, c2_colouring_img_2_png, c3_colouring_img_1_png, c3_colouring_img_2_png, c4_colouring_img_1_png, c4_colouring_img_2_png, c5_colouring_img_1_png, c5_colouring_img_2_png } from '../assets';
import { c6_colouring_img_1_png, c6_colouring_img_2_png, c7_colouring_img_1_png, c7_colouring_img_2_png, c8_colouring_img_1_png, c8_colouring_img_2_png, c9_colouring_img_1_png, c9_colouring_img_2_png, c10_colouring_img_1_png, c10_colouring_img_2_png } from '../assets';
import { c11_colouring_img_1_png, c11_colouring_img_2_png, c12_colouring_img_1_png, c12_colouring_img_2_png } from '../assets';

import { imgLKG_webp, imgUKG_webp, img1_webp, img2_webp, img3_webp, img4_webp, img5_webp, img6_webp, img7_webp, img8_webp, img9_webp, img10_webp, img11_webp, img12_webp } from '../assets';
import { imgLKG_low_webp, imgUKG_low_webp, img1_low_webp, img2_low_webp, img3_low_webp, img4_low_webp, img5_low_webp, img6_low_webp, img7_low_webp, img8_low_webp, img9_low_webp, img10_low_webp, img11_low_webp, img12_low_webp } from '../assets';

export const formatCategoryName = (name) => {
    const words = name.toUpperCase().split('-');

    // If there's a second word, wrap it with a span element with the desired class.
    if (words.length > 1) {
        words[1] = `<span class="theme-category-title-number">${words[1]}</span>`;
    }

    return words.map(word => `<div>${word}</div>`).join('');
};

export const instructions = {
    'drawing': [{
        eligibilityvenue: 'All students from Lower Kindergarten (LKG) to Class 12 are eligible to participate in this drawing & colouring competition. The competition will take place at your respective schools.',
        task: 'On the day of the competition, you will be provided with an A3 sheet. Your task is to draw and colour one of the three themes assigned to your class.',
        materials: 'Please bring and use your own preferred drawing and coloring tools, which may include pencils, crayons, or watercolors.',
        themeselection: 'Out of the three themes provided for your class, only one theme must be selected and drawn for the competition. Please refer to the syllabus page to view these themes',
    }],
    'colouring': [{
        eligibilityvenue: 'All students from Lower Kindergarten (LKG) to Class 12 are eligible to participate in this colouring competition. The competition will take place at your respective schools.',
        task: 'On the day of the competition, you will be provided with an A4 sheet containing two black and white line drawings, one on each side. Your task is to colour one of the two line drawings assigned to your class.',
        materials: 'Please bring and use your own preferred coloring tools, which may include pencils, crayons, or watercolors.',
        themeselection: 'Out of the two black and white line drawings provided for your class, only one line drawing must be selected and coloured for the competition.  Please refer to the syllabus page to download these line drawings for your practice.',
    }],
    'handwriting': [{
        eligibilityvenue: 'All students from Class 1 to Class 12 are eligible to participate in this handwriting competition. The competition will take place at your respective schools.',
        task: 'On the day of the competition, you will receive a handwriting sheet and a text to be copied. Your task is to accurately transcribe the text into the designated space on the sheet.',
        materials: 'For the handwriting competition, you may use an extra dark black pencil or a pen. If you opt for a pen, it can be either a blue or black ballpoint, gel, or ink pen.',
        themeselection: 'Download the handwriting practice sheet from the syllabus section of our website. The syllabus page also contains exercises and these are the same words you will see in the question paper during the exam. Practice writing these words at home. Remember, you don\'t need to memorize them. You\'ll be given these words in the question paper, and you\'ll need to copy them onto your answer sheet.',
    }],
}

export const classes = [
    {
        id: 0,
        name: 'LKG',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, imgLKG_low_png, imgLKG_png],
        imageWebp: [very_low_res_img_webp, imgLKG_low_webp, imgLKG_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'Drawing',
                description: 'Draw a kite',
            },
            {
                id: 1,
                category: 'Drawing',
                description: 'Draw a tree',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Puppy',
                colouringImagePng: [lkg_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Princess',
                colouringImagePng: [lkg_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],

    },
    {
        id: 1,
        name: 'UKG',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, imgUKG_low_png, imgUKG_png],
        imageWebp: [very_low_res_img_webp, imgUKG_low_webp, imgUKG_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'Drawing',
                description: 'Draw a car',
            },
            {
                id: 1,
                category: 'Drawing',
                description: 'Draw a fish',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Duck',
                colouringImagePng: [ukg_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Elephant',
                colouringImagePng: [ukg_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
    },
    {
        id: 2,
        name: '1',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img1_low_png, img1_png],
        imageWebp: [very_low_res_img_webp, img1_low_webp, img1_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Fruit - Mango',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'Building a Sandcastle',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A friendly turtle swimming in a calm pond',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Butterfly',
                colouringImagePng: [c1_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Animal Farm',
                colouringImagePng: [c1_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: true,
                containsPassage: false,
                description: '50 words',
                textSet: [
                    [
                        "1. Apple",
                        "2. Ball",
                        "3. Cat",
                        "4. Dog",
                        "5. Egg",
                        "6. Fish",
                        "7. Goat",
                        "8. Hat",
                        "9. Ice",
                        "10. Jam"
                    ],
                    [
                        "11. Amazing",
                        "12. Balloon",
                        "13. Captain",
                        "14. Delight",
                        "15. Explore",
                        "16. Garden",
                        "17. Giggle",
                        "18. Holiday",
                        "19. Imagine",
                        "20. Journey"
                    ],
                    [
                        "21. Butterfly",
                        "22. Dinosaur",
                        "23. Elephant",
                        "24. Giraffe",
                        "25. Helicopter",
                        "26. Kangaroo",
                        "27. Lightning",
                        "28. Pineapple",
                        "29. Rainbow",
                        "30. Strawberry"
                    ],
                    [
                        "31. Hippopotamus",
                        "32. Rhinoceros",
                        "33. Encyclopedia",
                        "34. Characterize",
                        "35. Conversation",
                        "36. Transportation",
                        "37. Celebration",
                        "38. Imagination",
                        "39. Extraordinary",
                        "40. Unbelievable"
                    ],
                    [
                        "41. Counterintelligence",
                        "42. Misunderstanding",
                        "43. Uncharacteristically",
                        "44. Incomprehensibility",
                        "45. Intercontinental",
                        "46. Institutionalization",
                        "47. Micromanagement",
                        "48. Counterproductive",
                        "49. Disproportionately",
                        "50. Individualistically"
                    ],
                ],
            },
        ],
    },

    {
        id: 3,
        name: '2',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img2_low_png, img2_png],
        imageWebp: [very_low_res_img_webp, img2_low_webp, img2_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Flag - Tricolor',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'Tree Swing',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A busy bee buzzing around a flower',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Boy flying a kite',
                colouringImagePng: [c2_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Campsite in a jungle',
                colouringImagePng: [c2_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: true,
                containsPassage: false,
                description: '30 proverbs',
                textSet: [
                    [
                        "1. Look before you leap.",
                        "2. Better late than never.",
                        "3. The fruit of patience is sweet.",
                        "4. Too many cooks spoil the broth.",
                        "5. A rolling stone gathers no moss.",
                        "6. Every cloud has a silver lining.",
                        "7. Cleanliness is next to godliness.",
                        "8. Empty vessels make the most noise.",
                        "9. The early bird catches the worm.",
                        "10. A penny saved is a penny earned.",
                        "11. Good things come to those who wait.",
                        "12. It's no use crying over spilt milk.",                        
                        "13. Don't bite the hand that feeds you.",
                        "14. The pen is mightier than the sword.",
                        "15. You can't judge a book by its cover.",
                        "16. Absence makes the heart grow fonder.",
                        "17. Drop by drop is the water pot filled.",                      
                        "18. An apple a day keeps the doctor away.",
                        "19. Necessity is the mother of invention.",
                        "20. Beauty is in the eye of the beholder.",
                        "21. You can't teach an old dog new tricks.",
                        "22. Don't count your chickens before they hatch.",
                        "23. A bird in the hand is worth two in the bush.",
                        "24. Hope for the best, but prepare for the worst.",
                        "25. The grass is always greener on the other side.",
                        "26. When the going gets tough, the tough get going.",
                        "27. Great minds think alike, but fools seldom differ.",
                        "28. You can catch more flies with honey than with vinegar.",
                        "29. A journey of a thousand miles begins with a single step.",
                        "30. You can lead a horse to water, but you can't make him drink.",
                    ],

                ],
            },
        ],
    },
    {
        id: 4,
        name: '3',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img3_low_png, img3_png],
        imageWebp: [very_low_res_img_webp, img3_low_webp, img3_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Tree - Banyan Tree',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'Rowing a Boat in the River',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A curious woodpecker pecking on a tree trunk',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Sandcastle in a beach',
                colouringImagePng: [c3_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Hot air Balloon',
                colouringImagePng: [c3_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: true,
                containsPassage: false,
                description: '30 solgans',
                textSet: [
                    [
                        "1. \"One nation, one heartbeat, united in our love for India!\"",
                        "2. \"India first, always and forever, in our hearts and actions!\"",
                        "3. \"With unity, strength, and progress, we march forward as one India!\"",
                        "4. \"Incredible India, with an indomitable spirit, united we rise and shine!\"",
                        "5. \"Patriotism is our power, driving us to build a brighter future for India!\"",
                        "6. \"United in our differences, we stand as one strong nation, proud to be Indians!\"",
                        "7. \"Honoring our past and building our future, we are the proud children of India!\"",
                        "8. \"As proud Indians, we are stronger together, committed to our nation's progress!\"",
                        "9. \"Salute the Motherland, our pride and our duty, as we strive for greatness together!\"",
                        "10. \"Hail India, a land of unity and diversity, where our strength lies in our togetherness!\""
                    ],
                    [
                        "11. \"By preserving their habitats, we give wildlife a fighting chance to thrive.\"",
                        "12. \"Stand up for wildlife; every species plays a crucial role in our ecosystem.\"",
                        "13. \"Wildlife protection is essential for the survival of our planet's biodiversity.\"",
                        "14. \"Conserve our natural resources to maintain the beauty and health of our planet.\"",
                        "15. \"Our duty is to safeguard nature's beauty and ensure it remains unspoiled for all.\"",
                        "16. \"Protecting nature today ensures a greener and healthier planet for future generations.\"",
                        "17. \"Every small effort to protect nature adds up to a significant impact on our environment.\"",
                        "18. \"Protecting the wild means preserving the natural harmony that sustains all life on Earth.\"",
                        "19. \"Together, we can ensure that future generations inherit a world rich in wildlife and natural beauty.\"",
                        "20. \"Saving wildlife is not just about animals; it's about preserving the delicate balance of our ecosystem.\""
                    ],
                    [
                        "21. \"Friendship multiplies joy and divides sorrow.\"",
                        "22. \"Friendship is the bond that brightens every day.\"",
                        "23. \"Friends are the mirrors who show us our best selves.\"",
                        "24. \"True friends are the family we choose for ourselves.\"",
                        "25. \"Friendship is the art of being there for one another.\"",
                        "26. \"Friends turn ordinary moments into cherished memories.\"",
                        "27. \"Friendship is the foundation of trust and understanding.\"",
                        "28. \"Friends are the anchors that keep us grounded through life's storms.\"",
                        "29. \"A true friend sees the first tear, catches the second, and stops the third.\"",
                        "30. \"Good friends are like stars; you don't always see them, but you know they're always there.\""
                    ],
                ],
            },
        ],
    },
    {
        id: 5,
        name: '4',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img4_low_png, img4_png],
        imageWebp: [very_low_res_img_webp, img4_low_webp, img4_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Bird - Peacock',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'Magic carpet ride',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A cute dolphin jumping out of the water',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Dinosaur in a jungle',
                colouringImagePng: [c4_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Children\'s Park',
                colouringImagePng: [c4_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: true,
                containsPassage: false,
                description: '10 short paragraphs',
                textSet: [
                    [
                        "1. India's Diversity: India is a vibrant blend of traditions, languages, and religions, shaping its diverse identity. With over 1,600 languages and various ethnic groups, its cultural richness is visible in daily customs, from cuisine to arts.",
                        "2. India's Economic Growth: India's rapidly expanding economy is fueled by agriculture, manufacturing, and services. This growth enhances living standards and establishes India as a significant global economic player.",
                        "3. Sustainable Tourism in India: With increased accessibility to travel, sustainable tourism is becoming vital in India. This approach allows visitors to appreciate India's beauty and heritage while minimizing environmental harm.",
                        "4. Education & Personal Development: Education is crucial for personal growth, enhancing world understanding and self-confidence. It develops critical thinking, problem-solving abilities, and decision-making skills, pivotal for success.",
                        "5. Education & Economic Growth: Education drives economic development by boosting workforce productivity and creativity. Higher education levels lead to improved job prospects and earnings, stimulating economic progress and reducing poverty.",
                    ],
                    [
                        "6. Education & Global Understanding: Education promotes a better grasp of global issues and diverse cultures, fostering tolerance and respect. It prepares individuals to tackle global challenges like environmental sustainability and conflicts, enhancing worldwide peace and stability.",
                        "7. Friendship: Friendship enriches life, providing joy and support in good and tough times. The enduring connections in friendships underscore the foundational role they play in personal happiness.",
                        "8. Building Friendships: Establishing friendships demands time, effort, and sincerity. Gestures like a smile or a kind word can start lasting relationships, crucial in today's fast-moving world for emotional support.",
                        "9. The Value of Friendship: Friendships uplift and motivate, offering support in difficult times and celebrating together in joyous moments. This reciprocal support profoundly enriches lives, making friendships invaluable.",
                        "10. Long-lasting Friendships: Built on trust, mutual respect, and understanding, long-lasting friendships develop strong bonds over time. These enduring relationships provide comfort and joy, becoming a consistent source of happiness.",
                    ],
                ],
            },
        ],
    },
    {
        id: 6,
        name: '5',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img5_low_png, img5_png],
        imageWebp: [very_low_res_img_webp, img5_low_webp, img5_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Reptile - King Cobra',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'Hot Air Balloon Journey',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A huge elephant spraying water on itself in a pond',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Lion Roaring in the jungle',
                colouringImagePng: [c5_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Boy playing with a dolphin',
                colouringImagePng: [c5_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: true,
                containsPassage: true,
                description: '5 short paragraphs, 1 passage',
                textSet: [
                    [
                        "1. Gratitude: Gratitude is a powerful, yet simple transformational tool. It shifts our perspective from what is missing in our lives to the abundance that is already present. This shift fills our hearts with joy and motivates us to give back to others. Remember to count your blessings, express your thanks, and carry an attitude of gratitude wherever you go.",
                        "2. Kindness: Kindness is an often underestimated but potent force. Simple gestures like a smile, offering a helping hand, or a word of encouragement can significantly brighten someone's day, lift their spirits, and initiate a wave of positivity. Therefore, it's important to always choose kindness.",
                        "3. Persistence Towards Success: The road to success is usually strewn with obstacles requiring hard work, dedication, and perseverance. Every step taken, no matter how minor it seems, moves you closer to your goals. Persist in your efforts, continue pushing forward, and have faith in your abilities.",
                        "4. Lifelong Learning: Learning is an endless journey that continues throughout life. Each day offers new insights, discoveries, and opportunities for growth that we might never have imagined. Stay inquisitive, keep questioning, and persistently seek knowledge.",
                        "5. Unity and Strength: True unity brings undeniable strength. It encourages collaboration, enhances teamwork, and establishes a supportive environment where everyone feels valued. Strive to work in unison, support your peers, and celebrate your shared successes together.",
                    ],
                ],
                passageSet: [
                    [
                        "In the heart of a deep, magical forest, where trees stretch high into the sky and small streams sing quietly, there is a secret: our Earth is a living treasure. Look! The leaves dance in the gentle breeze; the ground below is soft and full of life, holding the promise of new plants. Every flower, bug, and rock is part of Earth's big family. So, let's walk softly, and respect every bit of nature. Remember, every time we care for the Earth, we take care of ourselves too. By loving our planet, we fill our hearts with joy and kindness. Let's promise to be Earth's best friends, helping it smile with our every action!"
                    ],
                ],
            },
        ],
    },
    {
        id: 7,
        name: '6',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img6_low_png, img6_png],
        imageWebp: [very_low_res_img_webp, img6_low_webp, img6_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Emblem - Lion Capital of Ashoka',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A Race car riding in a Race Track',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A dinosaur roaring in a jungle',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Car driving in a forest road',
                colouringImagePng: [c6_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Tiger drinking water from a pond',
                colouringImagePng: [c6_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: true,
                description: '2 passages',
                passageSet: [
                    [
                        "Community, a network as intricate and vital as the roots of the banyan tree in the village square, sustains and nurtures. In the small village of Nandgaon, nestled in the lush greenery of Kerala, everyone knows each other by name; greetings are exchanged with a warm, familiar ease. \"Community,\" Mr. Krishnan, the village elder, often says, \"is our lifeline—like water for these fields.\" During the annual harvest festival, vibrant and noisy, the entire village comes together; saris and turbans in dazzling colors flutter in the breeze. Here, Mrs. Lalita, known for her potent coconut chutney, sets up a stall: \"Eat, everyone, it's made for sharing!\" Nearby, young Anil and his friends stage a play about village legends; their makeshift stage alive with drama and laughter. Above all, these gatherings reinforce a simple truth: in unity, there is strength, joy, and an enduring sense of belonging.",

                        "In a symphony of beeps, clicks, and whirrs, a computer springs to life; it’s more than a machine—it's a portal to vast worlds. Inside, each program runs like a river: smoothly, with purpose. We type—letters appear; we click—a new page opens. Every action is a command; every result, a response. As data zips from folder to folder, what marvels unfold! Isn’t it magical? Each file, each app, bridges us to knowledge and connections across the globe. And, as we sit before this glowing screen, let's pause; remember: this isn't just technology—it's the craftsmanship of countless minds, tirelessly coding, refining, and dreaming up the future, right at our fingertips.",
                    ],
                ],
            },
        ],
    },
    {
        id: 8,
        name: '7',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img7_low_png, img7_png],
        imageWebp: [very_low_res_img_webp, img7_low_webp, img7_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Animal - Bengal Tiger',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'An Astronaut walking on the moon',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A majestic phoenix rising from the flames',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Superhero saving kids from a buiding fire',
                colouringImagePng: [c7_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Kids walking in a farm land along with their pet',
                colouringImagePng: [c7_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: true,
                description: '2 passages',
                passageSet: [
                    [
                        "Time, as elusive as the monsoon winds that sweep across Delhi in July, waits for no one. At the stroke of noon—when the sun is at its zenith—Mrs. Kapoor, a retired school teacher, often remarks: \"Time flies, doesn't it?\" Her old, gold-rimmed clock, perched on the mantelpiece, ticks in agreement. Nearby, her grandson, Arjun, engrossed in assembling a puzzle, looks up briefly; he nods, adding, \"And it doesn't stop for us to catch up, either.\" Outside, the neighborhood kids, oblivious to the passage of hours, play cricket; their shouts and laughter punctuate the afternoon air. Meanwhile, in the kitchen, Mr. Kapoor, who's preparing tea, glances at the clock and murmurs, \"Tea time already? Well, time does march on!\" As the kettle whistles—a sharp, urgent sound—everyone is reminded, yet again, of time'’'s persistent march forward.",

                        "Within the boundless realms of our planet’s wilds, where majestic beasts roam and myriad birds soar, there lies a silent plea: the call for conservation. This plea, woven into the very fabric of the forests and the oceans, resonates with the urgency of the fading echoes of creatures at risk. Consider the elephant, grand and wise, whose memory spans generations; or the swift cheetah, whose speed is unrivaled, yet both stand at the brink, their futures uncertain. Shall we, the guardians of Earth's wonders, stand idle? Nay, we must act with courage and conviction! Let us forge alliances with nature, championing the cause of the vulnerable, and safeguarding the sanctity of life’s diversity. Each effort to protect a species, each initiative to preserve a habitat, is a step towards sustaining the vibrant tapestry of life that enriches our very existence. Embrace this noble quest, for in preserving the natural world, we preserve the legacy of humanity itself.",
                    ],
                ],
            },
        ],
    },
    {
        id: 9,
        name: '8',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img8_low_png, img8_png],
        imageWebp: [very_low_res_img_webp, img8_low_webp, img8_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: 'National Heritage Animal - Elephant',
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A roller coaster with twists and turns',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'An enormous dragon flying from a mountain peak',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Lion Cub introducing his new friend to his family',
                colouringImagePng: [c8_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Two indian soldiers guarding near the border',
                colouringImagePng: [c8_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: true,
                description: '2 passages',
                passageSet: [
                    [
                        "Curiosity, the spark behind every great discovery, ignites minds young and old alike. In the bustling lanes of Kolkata, nestled between centuries-old bookstores and aromatic tea stalls, is a little-known science center. \"Discovery begins here!\" proclaims a hand-painted sign at the entrance. Inside, Dr. Mehta, a physicist with twinkling eyes, demonstrates a solar-powered engine; his audience, a group of wide-eyed schoolchildren, watches in awe. \"Why does it move?\" asks Riya, a curious nine-year-old, pointing at the whirring contraption. Dr. Mehta, pleased by the question, explains with a smile: \"It's all about capturing sunlight and transforming it into energy.\" Meanwhile, outside, an impromptu experiment with magnifying glasses and leaves has commenced under the guidance of Miss Sunita, a local teacher; she encourages, \"Look closer, what do you see?\" As the day unfolds, each question asked and answered weaves a richer tapestry of understanding, reminding all that curiosity is not just about seeking answers—it's about nurturing a lifelong passion for learning.",

                        "In the intricate web of our modern world, where cities bustle and factories hum, a silent specter looms: pollution. This unseen adversary, born from the exhaust of cars and the chimneys of industry, creeps into our rivers, our skies, and our streets. It whispers a challenge to us, the stewards of the Earth. How, then, shall we respond? With resolve, with action! For it is within our power to wield science and innovation as tools in this critical battle. We must rise, not in whispers but in shouts, to defend the azure skies and the crystal waters that cradle all forms of life. Our mission is clear: to purge the toxins from our air, to cleanse the waters, and to restore the harmony of our environment. In this endeavor, each gesture of conservation, each choice for sustainability, resonates like a clarion call for change—a call to which we must answer, not tomorrow, but today.",
                    ],
                ],
            },
        ],
    },
    {
        id: 10,
        name: '9',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img9_low_png, img9_png],
        imageWebp: [very_low_res_img_webp, img9_low_webp, img9_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: ['A trekker reaching the summit of Mount Everest and proudly placing the Indian national flag at the peak.'],
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A scuba diver exploring the hidden depths of a vibrant coral reef surrounded by colorful marine life.',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A City Floating in the Sky, Held Aloft by Balloons',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Giraffe along with its kid eating leaves',
                colouringImagePng: [c9_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'A girl playing tree swing',
                colouringImagePng: [c9_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: false,
                containsPoemStory: true,
                description: '1 poem, 1 story',
                storyTitle: '',
                poemTitle: '',
                poem: [
                    [
                        "In the forest's heart, where secrets sleep,",
                        "The giants stand, so tall, so deep.",
                        "Each leaf, a whisper; each root, a hold,",
                        "Their story—ancient, brave, and bold."
                    ],
                    [
                        "But echoes stir of chainsaws' roar,",
                        "As trees fall, silenced forevermore.",
                        "Where once stood life, now barren, stark,",
                        "A shadowed land, once lush and dark."
                    ],
                    [
                        "Yet, hope glimmers in each new seed,",
                        "A call to act, a pledge to heed.",
                        "To plant, to nurture, to watch them grow,",
                        "In each young hand, the forest’s tomorrow."
                    ],
                ],
                story: [
                    [
                        "Once upon a time, in a village nestled between towering cliffs and the roaring sea, there was a legend of hidden caves said to house untold treasures. The villagers spoke of a labyrinth beneath the cliffs that only appeared during the lowest tide of the year. Sam, a spirited teenager with a love for mysteries, had heard these tales since he was a child. As the annual super low tide approached, he resolved to uncover the truth. Armed with a sturdy rope, a flashlight, and a dash of courage, he set out at dawn, his heart thrumming with excitement.",

                        "As the sun cast golden rays over the sea, Sam found the hidden entrance, just as the stories described, beneath a jagged boulder. Crawling through, he entered a vast network of caves. Each turn and echo amplified his thrill. The walls sparkled with mineral deposits, and distant water drips created an eerie melody. He ventured deeper, guided by his flashlight, until he stumbled upon a cavern, its center dominated by a massive, ancient shipwreck. “Incredible!” Sam gasped, his voice echoing off the cavern walls. He explored the ship, finding old sea chests and rusted weapons, remnants of a pirate era long gone.",

                        "Suddenly, Sam heard a rumbling above. Panic gripped him as he realized the tide was returning faster than expected! With his heart pounding, he raced back through the caves, the rising water nipping at his heels. Just as he squeezed through the cave entrance, the sea surged back with a mighty roar, sealing the entrance once again. Sam stood on the beach, drenched but exhilarated, clutching a small, ornate compass he had grabbed from the ship. He had escaped with a piece of history, and the caves had proven their thrill. Sam knew he would never forget the day he raced the tide—and won. The legend was real, and he had lived it!",

                    ],
                ],
            },
        ],
    },
    {
        id: 11,
        name: '10',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img10_low_png, img10_png],
        imageWebp: [very_low_res_img_webp, img10_low_webp, img10_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: ['An Indian astronaut making a historic landing on the Moon, planting the Indian flag on the lunar surface.'],
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A climber ascends a rocky cliff, overlooking a breathtaking view of the valley.',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A Mysterious Portal in an Ancient Ruin Leading to Another World',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Monkey saving his child from falling down a tree',
                colouringImagePng: [c10_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Helicopter saving people from flooded area.',
                colouringImagePng: [c10_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: false,
                containsPoemStory: true,
                description: '1 poem, 1 story',
                storyTitle: '',
                poemTitle: '',
                poem: [
                    [
                        "Sun kisses panels with morning's first light,",
                        "Winds whisper softly, powering the night.",
                        "Rivers run wild, yet harnessed with care,",
                        "Each drop a promise—clean power to share."
                    ],
                    [
                        "Fields stretch wide under azure skies,",
                        "Where turbines turn and the future lies.",
                        "Blades capture breezes from distant shores,",
                        "Spinning, they dance—energy that soars."
                    ],
                    [
                        "Beneath warm sands, the earth holds heat,",
                        "Geothermal gifts, so subtle, so sweet.",
                        "Tapped from the depths where molten secrets play,",
                        "Warmth turns to power in the brightest of ways."
                    ],
                    [
                        "Harness the elements, cherish their might,",
                        "In sun, wind, and water, find the light.",
                        "Renewable dreams, in hands skilled and true,",
                        "Building a world that's forever renewed."
                    ],
                ],
                story: [
                    [
                        "One sunny morning, Tommy and his friends decided to try something absolutely thrilling: skydiving! As they climbed into the plane, their hearts pounded with excitement and a touch of nervousness. Tommy looked out the window, watching the ground slowly fade away, and thought to himself, \"This is going to be the greatest adventure of our lives!\" Everyone was suited up, goggles on, and ready to jump. The instructor gave a thumbs up, and one by one, they leaped out of the plane into the blue sky.",

                        "As Tommy free-fell, the wind roared in his ears like a mighty lion. He felt as if he were flying, not falling; the world below was a colorful blur, and the sky above was a vast ocean of blue. Suddenly, Tommy spotted something bizarre and unnerving: a gleaming, metallic object hovering in the distance. It wasn't a plane or a bird; it was too strange and too still. An eerie chill ran down his spine as he realized it was a UFO! Despite the fear, curiosity propelled him closer to the mysterious craft, its surface reflecting the sunlight in odd patterns.",

                        "The ground was approaching quickly now. Tommy pulled the parachute cord, and with a strong jerk, he was yanked upwards, slowing his descent. The parachute bloomed above him like a giant, protective flower. Gliding down, he kept his eyes on the UFO, which, to his shock, started to ascend rapidly, vanishing into the clouds above. He landed smoothly on the soft grass, the camera safe in his hand and his mind buzzing with questions. His friends rushed over, eager to hear about his incredible encounter. It was a day of adventure that none of them would ever forget, and for Tommy, the sky had just unveiled a mystery that promised even more thrilling exploits!",

                    ],
                ],
            },
        ],
    },
    {
        id: 12,
        name: '11',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img11_low_png, img11_png],
        imageWebp: [very_low_res_img_webp, img11_low_webp, img11_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: ['An Indian athlete breaking a world record at an international sports event, with the national flag in the background.'],
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A skydiver experiences the thrill of freefall, with the earth approaching rapidly below and the expansive sky above.',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A Legendary Knight Riding a Majestic Lion into Battle',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'Holi festival celebration',
                colouringImagePng: [c11_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'Superhero saving a school bus from drowning in a river',
                colouringImagePng: [c11_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: false,
                containsPoemStory: true,
                description: '1 poem, 1 story',
                storyTitle: '',
                poemTitle: '',
                poem: [
                    [
                        "In the glow of screens, we stand,",
                        "Gazing at the future, vast and grand.",
                        "With every click and swipe, we mold,",
                        "A tale of tomorrow, yet untold—",
                        "What will be, is ours to hold!"
                    ],
                    [
                        "Through wires and waves, our thoughts connect,",
                        "Building bridges; we must protect.",
                        "Each byte and bit, with care, compose,",
                        "For every seed we plant, it grows—",
                        "Shaping worlds that no one knows."
                    ],
                    [
                        "Whispered secrets in circuits hum,",
                        "Machines learn from where we've come.",
                        "In their silence, wisdom waits,",
                        "As we unlock the digital gates—",
                        "Each choice we make, a fate creates."
                    ],
                    [
                        "So wield your power, young and bright,",
                        "Craft a future that's just and right.",
                        "In this dance of light and byte,",
                        "We're the keepers of the night—",
                        "Guiding stars with our insight!"
                    ],
                ],
                story: [
                    [
                        "In the dim light of the attic, Emma discovered an old, dusty book with a cover as black as a moonless night. Her fingers trembled as she opened it, revealing pages filled with strange symbols and cryptic writings. Suddenly, a cold wind swept through the room, extinguishing the candles and plunging her into darkness. She heard a whisper, so faint yet chillingly clear, \"Find the key...\" The room felt alive, and the shadows seemed to creep closer with each passing second.",

                        "Desperate to uncover the mystery, Emma searched the attic, her flashlight casting eerie shadows on the walls. Behind an ancient chest, she found a small, iron key with intricate engravings. As she held it, the whisper returned, louder this time, guiding her to a hidden door in the floor. With a deep breath, she inserted the key and turned it. The door creaked open, revealing a narrow staircase spiraling down into an unknown darkness. Heart pounding, she descended, each step echoing ominously.",

                        "At the bottom, Emma entered a secret chamber, where an ancient mirror stood, framed in twisted silver. As she approached, the air grew thick and the ground trembled. Her reflection in the mirror began to warp, twisting into a sinister grin. Thunder crashed as the reflection stepped out of the mirror, facing her with a menacing look. The real Emma, determined and fearless, grabbed the book and threw it into the mirror, shattering both into a million pieces. As the fragments fell, the storm ceased, and sunlight broke through the clouds, filling the chamber with light and ending the haunting forever.",

                    ],
                ],
            },
        ],
    },
    {
        id: 13,
        name: '12',
        color: '#ffffff',
        imagePng: [very_low_res_img_png, img12_low_png, img12_png],
        imageWebp: [very_low_res_img_webp, img12_low_webp, img12_webp],
        drawingSyllabus: [
            {
                id: 0,
                category: 'National Pride',
                description: ['Indian engineers building a bridge in a challenging terrain, symbolizing India\'s expertise in engineering and technology.'],
            },
            {
                id: 1,
                category: 'Adventure',
                description: 'A mountain biker speeds down a steep, rocky path surrounded by dense forest.',
            },
            {
                id: 2,
                category: 'Wild Creativity',
                description: 'A Futuristic Metropolis with Hovering Cars and Sky-high Gardens',
            },
        ],
        colouringSyllabus: [
            {
                id: 0,
                description: 'A cheetah being chased by a pack of hyenas',
                colouringImagePng: [c12_colouring_img_1_png],
                colouringImageWebp: [],
            },
            {
                id: 1,
                description: 'A brave Indian warrior fighting against his enemy',
                colouringImagePng: [c12_colouring_img_2_png],
                colouringImageWebp: [],
            },
        ],
        handwritingSyllabus: [
            {
                containsPhrases: false,
                containsPassage: false,
                containsPoemStory: true,
                description: '1 poem, 1 story',
                storyTitle: '',
                poemTitle: '',
                poem: [
                    [
                        "The future’s a canvas, vast and unseen,",
                        "Each choice paints a color—bold red or soft green.",
                        "Oh youth, with your hopes like stars burning bright,",
                        "Guide your brush with care through the shadows of night.",
                        "Your strokes today shape the world of our dreams."
                    ],
                    [
                        "In each small act, a seed of tomorrow is sown,",
                        "Growing landscapes lush from the truths that you've known.",
                        "So choose with your heart, let your actions be true,",
                        "For the earth you inherit is colored by you.",
                        "Together, we craft the future, not alone."
                    ],
                    [
                        "Hand in hand, we sketch on time’s endless scroll,",
                        "With dreams as our guide to fulfill every role.",
                        "Let kindness and courage be colors you blend,",
                        "For the art you create will echo, extend.",
                        "It’s your masterpiece, painted with soul."
                    ],
                    [
                        "So lift up your voice, let no dream be too grand,",
                        "Forge paths where others might hesitantly stand.",
                        "With every step forward, and each deed well placed,",
                        "A brighter tomorrow is what we’ll embrace.",
                        "Youth holds the tomorrow, firmly in hand."
                    ],
                ],
                story: [
                    [
                        "Once upon a time in the rugged mountains of Aspen, a group of teenage friends decided to take on the notorious \"Eagle's Claw\" trail with their mountain bikes. Early in the morning, they gathered at the trailhead, excitement buzzing in the air like electric currents. The path was known for its steep descents and sharp turns, with breathtaking views that could easily distract any rider. \"Stay sharp, guys, and keep your eyes on the path,\" warned Max, the most experienced biker of the group. As they pedaled forward, the whispering winds seemed to echo their racing heartbeats.",

                        "As the sun climbed higher, the challenge intensified. The trail wound through dense forests and over ancient, craggy rocks. Suddenly, a sharp turn came up faster than expected. \"Look out!\" shouted Emma, just as Jake lost his grip on the slippery path. Time seemed to freeze as Jake's bike skidded toward the edge. But with a spectacular leap, he regained control, landing safely but just inches from a sheer drop. Everyone's breath caught in their throats, and for a moment, the only sounds were the rustling leaves and pounding hearts. They paused, letting the adrenaline settle, before continuing with renewed caution and an even stronger bond.",

                        "The day was drawing to a close as they approached the final stretch, the most dangerous and thrilling part of the trail. The path narrowed, the shadows lengthened, and the air grew cooler. \"This is what we trained for!\" Max exclaimed. They picked up speed, their bikes seeming to fly over the rough terrain. As they rounded the last bend, the mountain opened up to reveal a stunning sunset that painted the sky in shades of orange and purple. They stopped at the cliff's edge, watching in awe. They had conquered the Eagle's Claw, not just with their skills, but with courage and teamwork. As they headed back, the setting sun cast long shadows behind them, heroes of their own epic adventure.",

                    ],
                ],
            },
        ],
    },
]