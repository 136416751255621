import React, { useState, useEffect } from 'react';
import './imageLoader.css';

const supportsWebP = () => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const result = (img.width > 0) && (img.height > 0);
      resolve(result);
    };
    img.onerror = () => resolve(false);
    img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  });
};

const ImageLoader = ({ baseID, className, allRes_png, allRes_webp, onImageLoad, fetchPriority }) => {
  const [currentSrc, setCurrentSrc] = useState(null); // Set initial state to null
  const [showSpinner, setShowSpinner] = useState(true);

  const [veryLowResSrc, setVeryLowResSrc] = useState('');
  const [lowResSrc, setLowResSrc] = useState('');
  const [highResSrc, setHighResSrc] = useState('');

  useEffect(() => {
    supportsWebP().then(supported => {
      if (supported) {
        setVeryLowResSrc(allRes_webp[0]);
        setLowResSrc(allRes_webp[1]);
        setHighResSrc(allRes_webp[2]);
      } else {
        setVeryLowResSrc(allRes_png[0]);
        setLowResSrc(allRes_png[1]);
        setHighResSrc(allRes_png[2]);
      }
      setCurrentSrc(veryLowResSrc);
    });
  }, [allRes_png, allRes_webp]);

  useEffect(() => {
    setShowSpinner(true);
    setCurrentSrc(null); // Reset currentSrc to null to hide the previous image
    if (!lowResSrc || !highResSrc) return; // Ensure the srcs are set

    const preloadLowResImage = new Image();
    preloadLowResImage.src = lowResSrc;
    preloadLowResImage.onload = () => {
      setCurrentSrc(lowResSrc);
      setShowSpinner(false);
      const preloadHighResImage = new Image();
      preloadHighResImage.src = highResSrc;
      preloadHighResImage.onload = () => {
        setCurrentSrc(highResSrc);
        if (onImageLoad) onImageLoad();
      };
    };
  }, [lowResSrc, highResSrc, onImageLoad]);

  return (
    <div key={baseID} className="image-loader-container">
      {showSpinner && <div className="spinner" />}
      {currentSrc && <img src={currentSrc} className={className} alt="Students" fetchpriority={!fetchPriority ? 'low' : fetchPriority} />} {/* Render conditionally */}
    </div>
  );
};

export default ImageLoader;
