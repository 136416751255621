import React from 'react';
import './mobileClassListPage.css'
import { MobileClassList } from './containers';

const MobileClassListPage = () => {
    return (
        <div className='MobileClassListPage'>
            <MobileClassList/>
        </div>
    )
}

export default MobileClassListPage