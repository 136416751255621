import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DelayedLink } from '../../../../hooks/DelayedLink';
import { classes } from '../../../../models/themes';
import { name_text_white_png, deco_1, board, drawing_img, colouring_img, handwriting_img } from '../../../../assets';
import './mobileClassList.css'
import { bounceContainerById } from '../../../../animations/BounceComponent';
import ImageLoader from '../../../../utils/ImageLoader';

const MobileClassList = () => {

    const { competitionName, className } = useParams();
    const competitionId = competitionName === "drawing" ? 0 : competitionName === "colouring" ? 1 : 2;

    function getCompetitionName(competitionId, isFirstCaps, isFullCaps) {
        switch (competitionId) {
            case 0:
                return (isFirstCaps ? "Drawing & Colouring" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
            case 1:
                return (isFirstCaps ? "Colouring" : isFullCaps ? "COLOURING" : "colouring");
            case 2:
                return (isFirstCaps ? "Handwriting" : isFullCaps ? "HANDWRITING" : "handwriting");
            default:
                return (isFirstCaps ? "Drawing" : isFullCaps ? "DRAWING & COLOURING" : "drawing");
        }
    }

    function getPageColorCode(competitionId, top, bottom, className) {
        switch (competitionId) {
            case 0:
                return (top ? "#6899dd" : bottom ? "#c7ddfc" : "#39d4ff");
            case 1:
                return (top ? "#a8ee8c" : bottom ? "#daffcb" : "#7aff78");
            case 2:
                return (top ? "#e8d470" : bottom ? " #faf0c0" : "#fff478");
            default:
                return (top ? "#6899dd" : bottom ? "#c7ddfc" : "#39d4ff");
        }
    }

    function getImageSrc(competitionId) {
        switch (competitionId) {
            case 0:
                return [drawing_img, drawing_img, drawing_img];
            case 1:
                return [colouring_img, colouring_img, colouring_img];
            case 2:
                return [handwriting_img, handwriting_img, handwriting_img];
        }
    }

    useEffect(() => {
        document.title = `Amazing Brushes - ${getCompetitionName(competitionId, true, false)} - Choose Class`
    }, []);

    function handleModalClassClick(classID) {
        navigate(`/${competitionName}/syllabus/${classID > 1 ? "class-" + classes[classID].name : classes[classID].name}`, { replace: false });
    }


    const navigate = useNavigate();

    return (
        <div className="mobile-classes">
            <div className="mobile-classes-content">
                <div className='mobile-classes-top-container'>
                    <div className='mobile-classes-top-left-container'>
                        <div className='mobile-classes-top-row' style={{ '--competition-top-colour': getPageColorCode(competitionId, false, true) }}>
                            <div className='mobile-classes-text-logo'>
                                <img src={name_text_white_png} className='mobile-classes-text-logo-img' alt="Amazing Brushes Name Text Image" fetchpriority="high"></img>
                            </div>
                        </div>
                        <div className='mobile-classes-title-container'>

                            <div className='mobile-classes-competition-title-section'>
                                <div className='mobile-classes-competition-title'>
                                    <div className='mobile-classes-competition-title-top'>
                                        {getCompetitionName(competitionId, true, false)}
                                    </div>
                                    <div className='mobile-classes-competition-title-bottom'>
                                        Competition
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-classes-top-right-container'>
                    <div className='mobile-classes-page-class-image-container'>
                            <ImageLoader
                                baseID={`MobileClassListCompetitionImage${competitionId}`}
                                className={'mobile-classes-list-competiiton-image'}
                                allRes_png={getImageSrc(competitionId)}
                                allRes_webp={getImageSrc(competitionId)}
                                fetchPriority={'high'}
                            />
                        </div>  
                    </div>
                </div>
                <div className='mobile-classes-bottom-container' id='ThemeDetailsForClass'>
                    <div className='mobile-classes-choose-class-text' style={{ '--background-colour': getPageColorCode(competitionId, false, true) }}>Select Class</div>
                    <div className='mobile-classes-class-list' id='ModalClassList' style={{ '--background-colour': getPageColorCode(competitionId, true, false) }}>
                        {classes.map(class_obj => (
                            competitionId == 2 && class_obj.id <= 1 ? null : (
                                <div
                                    key={class_obj.id}
                                    className={`mobile-classes-class-container`}
                                    id={`ClassContainer${class_obj.id}`}
                                    onClick={() => handleModalClassClick(class_obj.id)}
                                    style={{'backgroundColor' : getPageColorCode(competitionId, false, false, true)}}
                                >
                                    <div className={`mobile-classes-class-text ${class_obj.id <= 1 ? 'mobile-classes-KG-class-text' : ''}`}>
                                        {class_obj.id > 1 ?
                                            <div className='mobile-classes-class-title'>Class</div>
                                            :
                                            <></>}
                                        <div className='mobile-classes-class-title-class-number'>
                                            {class_obj.name}
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MobileClassList